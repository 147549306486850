import { Box, Menu, MenuItem, Popover } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useWorkspaces } from '../api/hooks/workspaces/useWorkspaces'
import Divider from '@mui/material/Divider'
import PlusIcon from '../assets/icons/Plus.svg'
import TextAa from '../assets/icons/TextAa.svg'
import TrashRed from '../assets/icons/TrashRed.svg'
import RedTick from '../assets/icons/RedTick.svg'
import { getWorkspaceID } from '../utils'
import {
    switchWorkspace,
    updateWorkspaceName,
    updateWorkspaces,
} from '../store/actions/authActions'
import { useDispatch } from 'react-redux'
import { setResetTrigger } from '../store/actions/uiActions'
import { ROUTES } from '../router/routes'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { CreateEditWorkspaceModal } from './screens/Canvas/ActionsDialogs/CreateEditWorkspaceModal'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useUserInfo } from '../api/hooks/user/useUserInfo'
import { DeleteWorkspaceModal } from './screens/Canvas/ActionsDialogs/DeleteWorkspaceModal'
import { logEvent } from '../api/hooks/logs/api'
import userflow from 'userflow.js'

export const SwitchWorkspacePopover = ({ open, anchorEl, onClose }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openRenameModal, setOpenRenameModal] = useState(false)
    const [openCreateWorkspaceModal, setOpenCreateWorkspaceModal] = useState(false)
    const [snackbar, setSnackbar] = useState(null)

    const { workspaces } = useWorkspaces()
    const { user } = useUserInfo()
    const workspaceID = getWorkspaceID()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleCreateWorkspaceSuccess = (newWorkspace, response) => {
        if (openCreateWorkspaceModal) {
            dispatch(updateWorkspaces(response))
            dispatch(switchWorkspace(newWorkspace.workspaceID, newWorkspace.workspaceName))
            dispatch(setResetTrigger())
            setOpenCreateWorkspaceModal(false)
        } else if (openRenameModal) {
            dispatch(setResetTrigger())
            dispatch(
                updateWorkspaceName(newWorkspace.workspaceName, newWorkspace.workspaceID, response)
            )
            setOpenRenameModal(false)
        }
        onClose()
    }

    const handleSelectWorkspace = (workspaceID) => {
        onClose()
        const workspaceName = getNameFromWorkspaceID(workspaceID)
        if (workspaceName) {
            dispatch(switchWorkspace(workspaceID, workspaceName))
            dispatch(setResetTrigger())
            navigate(ROUTES.CANVAS_DASHBOARD)
            try {
                userflow.track('workspace_changed', {
                    workspaceID: workspaceID,
                    workspaceName: workspaceName,
                })
                // console.log('sent userflow event', 'switchWorkspace', {
                //     type: 'switchWorkspace',
                //     workspace: {
                //         workspaceID: workspaceID,
                //         name: workspaceName,
                //     },
                // })
            } catch (error) {}
            logEvent('switchWorkspace', {
                type: 'switchWorkspace',
                workspace: {
                    workspaceID: workspaceID,
                    name: workspaceName,
                },
            })
        }
    }

    const getNameFromWorkspaceID = (workspaceID) => {
        const workspace = workspaces.find((workspace) => workspace.workspaceID === workspaceID)
        return workspace ? workspace.name : ''
    }

    const getSortedWorkspaces = () => {
        return [...workspaces].sort((a, b) => a.name.localeCompare(b.name))
    }

    return (
        <>
            <Menu
                id={open ? 'workspace-menu' : undefined}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ padding: '8px 16px' }}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '12px',
                            fontFamily: 'Inter',
                            color: '#412747',
                        }}
                    >
                        Switch Workspace
                    </Typography>
                </Box>
                <Box sx={{ maxHeight: '250px', overflow: 'auto' }}>
                    {getSortedWorkspaces().map((workspace) => (
                        <MenuItem
                            value={workspace.workspaceID}
                            sx={{
                                color: '#412747',
                                fontSize: '14px',
                                justifyContent: 'space-between',
                                backgroundColor:
                                    workspaceID === workspace.workspaceID ? '#F9F9F9' : '',
                            }}
                            key={workspace.id}
                            onClick={() => handleSelectWorkspace(workspace.workspaceID)}
                        >
                            <Typography
                                sx={{ color: '#412747', fontSize: '14px', alignItems: 'center' }}
                            >
                                {workspace.name}
                            </Typography>
                            {workspaceID === workspace.workspaceID && (
                                <img style={{ width: 18 }} src={RedTick} alt={'RedTick'} />
                            )}
                        </MenuItem>
                    ))}
                </Box>
                <Divider sx={{ mb: '6px' }} />
                <MenuItem
                    onClick={() => {
                        onClose()
                        setOpenCreateWorkspaceModal(true)
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '7px' }}>
                        <img style={{ width: 18 }} src={PlusIcon} alt={'Plus'} />
                        <Typography
                            sx={{ color: '#412747', fontSize: '14px', alignItems: 'center' }}
                        >
                            Create Workspace
                        </Typography>
                    </Box>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onClose()
                        setOpenRenameModal(true)
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                        <img style={{ width: 18 }} src={TextAa} alt={'TextAa'} />
                        <Typography
                            sx={{ color: '#412747', fontSize: '14px', alignItems: 'center' }}
                        >
                            Rename Workspace
                        </Typography>
                    </Box>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onClose()
                        setOpenDeleteModal(true)
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                        <img style={{ width: 18 }} src={TrashRed} alt={'Trash'} />
                        <Typography
                            sx={{ color: '#412747', fontSize: '14px', alignItems: 'center' }}
                        >
                            Delete Workspace
                        </Typography>
                    </Box>
                </MenuItem>
            </Menu>
            {openCreateWorkspaceModal && (
                <CreateEditWorkspaceModal
                    open={openCreateWorkspaceModal}
                    close={() => setOpenCreateWorkspaceModal(false)}
                    workspaceName={getNameFromWorkspaceID(workspaceID)}
                    actionType="createWorkspace"
                    title="Create workspace"
                    buttonText="Create Workspace"
                    setSnackbar={setSnackbar}
                    userName={user.userName}
                    onError={(msg) => setSnackbar({ msg, severity: 'error' })}
                    onSuccess={handleCreateWorkspaceSuccess}
                    isCreate={true}
                />
            )}
            {openRenameModal && (
                <CreateEditWorkspaceModal
                    open={openRenameModal}
                    close={() => setOpenRenameModal(false)}
                    initialName={getNameFromWorkspaceID(workspaceID)}
                    workspaceID={workspaceID}
                    actionType="renameWorkspace"
                    title="Rename workspace"
                    buttonText="Rename Workspace"
                    setSnackbar={setSnackbar}
                    onSuccess={handleCreateWorkspaceSuccess}
                    onError={(msg) => setSnackbar({ msg, severity: 'error' })}
                    isCreate={false}
                />
            )}
            {openDeleteModal && (
                <DeleteWorkspaceModal
                    open={openDeleteModal}
                    close={() => {
                        setOpenDeleteModal(false)
                        onClose()
                    }}
                    workspaceName={getNameFromWorkspaceID(workspaceID)}
                    workspaceID={workspaceID}
                    setSnackbar={setSnackbar}
                />
            )}
            {snackbar && (
                <Snackbar
                    open={!!snackbar}
                    autoHideDuration={5000}
                    onClose={() => setSnackbar(null)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={() => setSnackbar(null)}
                        severity={snackbar?.severity}
                        variant="filled"
                    >
                        {snackbar?.msg}
                    </Alert>
                </Snackbar>
            )}
        </>
    )
}
