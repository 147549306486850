import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import * as React from 'react'
import ScoopLogo from '../../assets/logo/ScoopLogo.svg'
import { DEFAULT_WORKSPACE_NAME, OLD_DEFAULT_WORKSPACE_NAME } from '../SwitchWorkspaceDialog'
import UserProfileModal from './Profile'
import { navbarItems } from './navbarItems'
import NavbarItem from './NavBarItem'
import UserCircle from '../../assets/icons/UserCircle.svg'
import './NavBar.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CaretUpDown from '../../assets/icons/CaretUpDown.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logout, switchWorkspace } from '../../store/actions/authActions'
import { ROUTES } from '../../router/routes'
import { useEffect, useRef, useState } from 'react'
import { useApi } from '../../api/api'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Typography from '@mui/material/Typography'
import { LinearProgress, Tooltip } from '@mui/material'
import {
    toggleSwitchWorkspaceModal,
    canShowTourAlert,
    setResetTrigger,
    setOnboardingChecklist,
} from '../../store/actions/uiActions'
import Welcome from '../../assets/images/Welcome.svg'
import { useWorkspaces } from '../../api/hooks/workspaces/useWorkspaces'
import { SwitchWorkspacePopover } from '../SwitchWorkspacePopover'
import { getLastUsedWorkspaces, getWorkspaceID } from '../../utils'
import Button from '../common/Button/Button'
import { Checklist } from '../common/Onboarding/Checklist'
import WhiteRocket from '../../assets/icons/WhiteRocket.svg'
import { useUserInfo } from '../../api/hooks/user/useUserInfo'
import userflow from 'userflow.js'
import { AccountDropdown } from '../AccountDropdown/AccountDropdown'

const USERFLOW_TOKEN = 'ct_4abdvu4v7vfvzlt6rn4crgdbx4'

const NavBar = ({ workspaceName }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const switchAnchorRef = useRef(null)
    const [alertOpen, setAlertOpen] = useState(false)
    const checklistRef = useRef(null)
    const [openCheckList, setOpenCheckList] = useState(null)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const defaultTourWorkspace = useSelector((state) => state.auth.defaultTourWorkspace)
    const userID = useSelector((state) => state.auth.userID)
    const userName = useSelector((state) => state.auth.userName)
    const onboardingChecklist = useSelector((state) => state.ui.onboardingChecklist)
    const readyToShowAlert = useSelector((state) => state.ui.canShowTourAlert)
    const { canvasID } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMenuOpen = Boolean(anchorEl)
    const [showUserProfile, setShowUserProfile] = useState(false)
    const { postData: postDataDefault } = useApi()
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)
    const isWorkspacePopoverOpen = useSelector((state) => state.ui.switchWorkspaceModalOpen)

    const [openWorkspaceActionsModal, setOpenWorkspaceActionsModal] = useState(false)

    const { workspaces: workspacesAccessible } = useWorkspaces()
    const { user } = useUserInfo()

    const showChecklistButton =
        onboardingChecklist !== null && user?.personalWorkspace === workspaceID

    useEffect(() => {
        let currentWorkspace = workspacesAccessible.find(
            (workspace) => workspace.workspaceID === workspaceID
        )

        if (!readyToShowAlert) return
        if (currentWorkspace) {
            if (defaultTourWorkspace) {
                if (
                    defaultTourWorkspace === currentWorkspace.workspaceID ||
                    currentWorkspace.name === DEFAULT_WORKSPACE_NAME
                ) {
                    setAlertOpen(true)
                } else {
                    alertOpen && setAlertOpen(false)
                    readyToShowAlert && dispatch(canShowTourAlert(false))
                }
            } else {
                if (currentWorkspace.name === DEFAULT_WORKSPACE_NAME) {
                    setAlertOpen(true)
                } else {
                    alertOpen && setAlertOpen(false)
                    readyToShowAlert && dispatch(canShowTourAlert(false))
                }
            }
        } else setAlertOpen(false)
    }, [workspacesAccessible, workspaceID, defaultTourWorkspace, readyToShowAlert])

    useEffect(() => {
        const redirect = localStorage.getItem('redirectUrl')
        if (redirect) {
            navigate(redirect)
            localStorage.removeItem('redirectUrl')
        }
    }, [])

    useEffect(() => {
        if (userID) {
            try {
                // For userflow
                const currentTimestamp = Date.now()
                const currentDate = new Date(currentTimestamp)

                // Format the date as ISO 8601 in the PST time zone
                const pstTime = new Date().toLocaleString('en-US', {
                    timeZone: 'America/Los_Angeles',
                    hour12: false,
                })
                const isoDate = new Date(pstTime).toISOString() + '+8'

                userflow.init(USERFLOW_TOKEN)
                userflow.identify(userID, {
                    name: userName,
                    email: userName,
                    signed_up_at: isoDate,
                })
                // console.log('Userflow identify event sent: ', userID, {
                //     name: userName,
                //     email: userName,
                //     signed_up_at: isoDate,
                // })
            } catch (error) {
                console.error('Error pushing data to userflow:', error)
            }
        }
    }, [userID])

    // If not detault workspace has been selected (i.e. new user)
    useEffect(() => {
        let lastUsedWorkspaces = getLastUsedWorkspaces()
        const lastUsed = lastUsedWorkspaces.get(userID)
        if (lastUsed && !getWorkspaceID()) {
            dispatch(switchWorkspace(lastUsed[0], lastUsed[1]))
        } else {
            if (workspacesAccessible.length > 0 && !getWorkspaceID()) {
                const tour = workspacesAccessible.find(
                    (w) => w.workspaceID === defaultTourWorkspace
                )
                const tourWorkspace = workspacesAccessible.find(
                    (workspace) =>
                        workspace.name === DEFAULT_WORKSPACE_NAME ||
                        workspace.name === OLD_DEFAULT_WORKSPACE_NAME
                )
                if (tour) dispatch(switchWorkspace(tour.workspaceID, tour.name))
                else dispatch(switchWorkspace(tourWorkspace?.workspaceID, tourWorkspace?.name))
                dispatch(setResetTrigger())
                navigate(ROUTES.CANVAS_DASHBOARD)
            }
        }
    }, [workspacesAccessible])

    // useEffect(() => {
    //     if (
    //         showChecklistButton &&
    //         Boolean(checklistRef) &&
    //         location.pathname === ROUTES.SOURCES &&
    //         openCheckList === null
    //     )
    //         setTimeout(() => setOpenCheckList(true), 500)
    // }, [workspaceID, location.pathname])

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const RenderTooltip = ({ children }) => {
        return <Tooltip title={'Switch Workspace'}>{children}</Tooltip>
    }

    const WorkspaceExplainer = () => {
        return (
            <Box
                sx={{
                    position: 'fixed',
                    display: 'inline-block',
                    top: '64px',
                    left: '20px',
                    width: '377px',
                    zIndex: 1000,
                    boxShadow: '0px 0px 20px 0px #14092A40',
                    borderRadius: '5px',
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ backgroundColor: '#422e47', display: 'flex', justifyContent: 'center' }}>
                    <img
                        style={{
                            height: '165px',
                            width: '190px',
                            position: 'relative',
                            objectFit: 'contain',
                            bottom: '-21px',
                        }}
                        alt={'image'}
                        src={Welcome}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                        padding: '24px',
                        backgroundColor: 'white',
                        borderRadius: '0 0 5px 5px',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Typography
                            sx={{
                                color: '#201024',
                                fontWeight: 600,
                                fontSize: '24px',
                                fontFamily: 'Inter',
                            }}
                        >
                            Your Demo Workspace
                        </Typography>
                        <Typography
                            sx={{
                                color: '#635566',
                                fontWeight: 400,
                                fontSize: '14px',
                                fontFamily: 'Inter',
                            }}
                        >
                            Here you can explore Scoop with sample data and see example canvases.
                            Click the Workspace switcher to get back to your Workspace and use your
                            own data.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Button
                            fullWidth
                            className={'button button-purple small'}
                            onClick={() => setAlertOpen(false)}
                        >
                            Got it
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <>
            {!isGuestMode && (
                <AppBar>
                    <Toolbar className={'navbar'}>
                        <Box className={'scoop-logo-container'}>
                            <img
                                src={ScoopLogo}
                                alt="Scoop Logo"
                                onClick={() => navigate(ROUTES.CANVAS_DASHBOARD)}
                            />
                        </Box>
                        <Box className={'navbar-items-container'}>
                            <Box
                                name={'switch-workspace-button'}
                                ref={switchAnchorRef}
                                onClick={() => dispatch(toggleSwitchWorkspaceModal(true))}
                                className={'navbar-item'}
                                sx={{ padding: '0 16px' }}
                            >
                                <RenderTooltip>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Typography className={'inter'}>{workspaceName}</Typography>
                                        <img src={CaretUpDown} alt="Switch Workspace" />
                                    </Box>
                                </RenderTooltip>
                            </Box>
                            {navbarItems.map((item, index) => (
                                <NavbarItem
                                    key={index}
                                    icon={item.icon}
                                    label={item.label}
                                    route={item.route}
                                    selected={location.pathname.includes(item.route)}
                                    onClick={() => navigate(item.route)}
                                />
                            ))}
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex',
                                    gap: '14px',
                                    alignItems: 'center',
                                },
                            }}
                        >
                            {showChecklistButton && (
                                <Box
                                    ref={checklistRef}
                                    sx={{
                                        display: 'flex',
                                        padding: '8px 16px',
                                        borderRadius: '50px',
                                        gap: '8px',
                                        backgroundColor: '#E50B54',
                                        alignItems: 'center',
                                        outline: openCheckList ? '2px solid #FFA6C3' : 'none',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            backgroundColor: '#bf0a46',
                                        },
                                    }}
                                    onClick={() => setOpenCheckList(true)}
                                >
                                    <img src={WhiteRocket} alt={'Rocket'} />
                                    <Box sx={{ flex: 1, borderRadius: '50px', overflow: 'hidden' }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={Number(onboardingChecklist || 0) * 33.33}
                                            sx={{
                                                borderRadius: '50px',
                                                backgroundColor: '#990034 !important',
                                                minWidth: '38px',
                                                height: '6px',
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'white !important',
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            fontFamily: 'Inter',
                                        }}
                                    >
                                        {onboardingChecklist || 0}/3
                                    </Typography>
                                </Box>
                            )}
                            <a
                                href="https://www.scoopanalytics.com/help"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Box aria-label="show new notifications" className={'navbar-item'}>
                                    <HelpOutlineIcon sx={{ color: 'white', fontWeight: 400 }} />
                                </Box>
                            </a>
                            <Box
                                className={'navbar-item'}
                                aria-label="account of current user"
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                sx={{ color: 'white' }}
                            >
                                <img src={UserCircle} alt={'User Circle'} />
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}
            <AccountDropdown
                anchorEl={anchorEl}
                isMenuOpen={isMenuOpen}
                setAnchorEl={setAnchorEl}
                workspaceName={workspaceName}
            />
            <SwitchWorkspacePopover
                open={isWorkspacePopoverOpen}
                anchorEl={switchAnchorRef.current}
                onClose={() => dispatch(toggleSwitchWorkspaceModal(false))}
            />
            {
                <Checklist
                    open={openCheckList}
                    onClose={() => setOpenCheckList(false)}
                    anchorEl={checklistRef.current}
                    onPermanentClose={() => {
                        setOpenCheckList(false)
                        dispatch(setOnboardingChecklist(null))
                    }}
                />
            }
            {alertOpen && <WorkspaceExplainer />}
        </>
    )
}

export default NavBar
