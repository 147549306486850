import './Login.css'
import { Box, Link, Tooltip, FormControlLabel, Checkbox } from '@mui/material'
import ScoopLogo from '../../../assets/logo/NewLogo.svg'
import Typography from '@mui/material/Typography'
import Input from '../../common/Input/Input'
import React, { useEffect, useState } from 'react'
import './Login.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Button from '../../common/Button/Button'
import GoogleIcon from '../../../assets/icons/Google.svg'
import LoginLogos from '../../../assets/images/LoginAd1.png'
import Scoop from '../../../assets/images/Scoop.png'
import ScoopLogin from '../../../assets/images/ScoopLogin.png'
import Sheets from '../../../assets/images/sheets.svg'
import Charts from '../../../assets/images/charts.svg'
import LoginEmailIcon from '../../../assets/icons/LoginEmail.svg'
import OrganizationIcon from '../../../assets/icons/Organization.svg'
import PlusIcon from '../../../assets/icons/Plus.svg'
import Trash from '../../../assets/icons/Trash.svg'
import CheckGreen from '../../../assets/icons/CheckGreen.svg'
import Salesforce from '../../../assets/images/Salesforce.png'
import Apollo from '../../../assets/images/Apollo.png'
import Attio from '../../../assets/images/Attio.png'
import Quickbooks from '../../../assets/images/Quickbooks.png'
import Pipedrive from '../../../assets/images/Pipedrive.png'
import Monday from '../../../assets/images/Monday.png'
import Close from '../../../assets/images/Close.png'
import Airtable from '../../../assets/images/Airtable.png'
import Copper from '../../../assets/images/Copper.png'
import Google from '../../../assets/images/Google.png'
import Hubspot from '../../../assets/images/Hubspot.png'
import Jira from '../../../assets/images/Jira.png'
import Netsuite from '../../../assets/images/Netsuite.png'
import SOC from '../../../assets/images/SOC.png'
import useSignUp from './hooks/useSignUp'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import useSignIn from './hooks/useSignIn'
import { federatedSignIn } from '../../../api/auth'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../router/routes'
import { ScoopLoader } from '../../common/Spinner/ScoopLoader'
import { CreateWorkspace } from './CreateWorkspace'
import { RecipeNavbar } from '../Recipe/Recipe'
import { useRecoverPassword } from './hooks/useRecoverPassword'
import CookieConsent from 'react-cookie-consent'
import { logEvent } from '../../../api/hooks/logs/api'
import { Carousel } from 'react-responsive-carousel'

const blackColor = '#201024'
const redColor = '#E50B54'

const images = [LoginLogos, Sheets, Charts]

const features = [
    {
        title: 'AI-POWERED REPORTS',
        description: 'Instantly generate presentation-ready insights.',
    },
    {
        title: 'NO CODE NEEDED',
        description: 'Analyze data without SQL or technical skills.',
    },
    {
        title: 'LIVE DATA INTEGRATION',
        description: 'Connect and refresh multiple sources in real time.',
    },
    {
        title: 'TRACK CHANGES AUTOMATICALLY',
        description: 'Snapshot and monitor trends effortlessly.',
    },
    {
        title: 'EASY SHARING',
        description: 'Export to PowerPoint or share interactive live links.',
    },
]

const logos = [
    { src: Salesforce, alt: 'Salesforce' },
    { src: Apollo, alt: 'Apollo' },
    { src: Attio, alt: 'Attio' },
    { src: Hubspot, alt: 'Hubspot' },
    { src: Quickbooks, alt: 'Quickbooks' },
    { src: Pipedrive, alt: 'Pipedrive' },
    { src: Monday, alt: 'Monday' },
    { src: Close, alt: 'Close' },
    { src: Airtable, alt: 'Airtable' },
    { src: Copper, alt: 'Copper' },
    { src: Google, alt: 'Google' },
    { src: Jira, alt: 'Jira' },
    { src: Netsuite, alt: 'Netsuite' },
]

const carouselLabels = [
    'AI Powered BI Presentations',
    'Blend & manipulate spreadsheets',
    'Tell compelling data stories',
]

const emails = [
    'john.doe@example.com',
    'jane.smith@example.com',
    'michael.johnson@example.com',
    'emily.davis@example.com',
]

const renderIndicator = (onClickHandler, isSelected) => {
    const indicatorStyle = {
        background: isSelected ? redColor : 'rgba(255, 255, 255, 0.15)',
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'inline-block',
        margin: '0 8px',
        cursor: 'pointer',
        strokeWidth: '6px',
        stroke: 'rgba(255, 24, 101, 0.20)',
    }

    const customClickHandler = () => {
        // Call the default onClickHandler
        if (onClickHandler) {
            onClickHandler()
        }

        // log event
        logEvent('LoginScreenScrolling', [
            { title: 'Logged out/new user scrolling through login carousel' },
        ])
    }

    return (
        <div
            style={indicatorStyle}
            onClick={customClickHandler}
            onKeyDown={customClickHandler}
            role="button"
        />
    )
}

export const Login = ({ loginRoute }) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const navigate = useNavigate()
    const [step, setStep] = useState(0)
    const [recoverPasswordStep, setRecoverPasswordStep] = useState(0)
    const [isLogin, setIsLogin] = useState(loginRoute)
    const [showSnackbar, setShowSnackbar] = useState(null)
    const [verificationCode, setVerificationCode] = useState(null)
    const [errors, setErrors] = useState({
        fullName: '',
        email: '',
        password: '',
    })

    // this is true if the product was entered with forceLogin=[email] in the URL
    const [forceUserEmail, setForceUserEmail] = useState(null)
    const [forceUserName, setFoceUserName] = useState(null)
    const [forcePassword, setForcePassword] = useState(null)

    const {
        signUpUser,
        handleSignUpInputChange,
        handleSignUp,
        isSignUpLoading,
        handleConfirmSignup,
        handleGoToCanvas,
    } = useSignUp(step, setStep, setShowSnackbar, setErrors)

    const { signInUser, handleSignInInputChange, handleSignIn, isSignInLoading } = useSignIn(
        setShowSnackbar,
        setErrors
    )

    const {
        email,
        newPassword,
        handleEmailChange,
        handleRecoverPasswordChange,
        handleStartPWDReset,
        handleFinishPWDReset,
    } = useRecoverPassword(setShowSnackbar, setRecoverPasswordStep, () => {
        setIsLogin(true)
        setStep(0)
    })

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        const recipeMapping = searchParams.get('recipeMapping')
        // forceLogin is a query param that forces the user to login as a certain user email; it may be in a query or might have been set in local storage
        const forceLogin = searchParams.get('forceLogin') || localStorage.getItem('forceLogin')

        if (forceLogin) {
            if (forceLogin.includes('|')) {
                setForceUserEmail(forceLogin.split('|')[0])
                setFoceUserName(forceLogin.split('|')[1])
                handleSignInInputChange('email', forceLogin.split('|')[0])
                handleSignUpInputChange('email', forceLogin.split('|')[0])
                handleSignUpInputChange('fullName', forceLogin.split('|')[1])
            } else {
                setForceUserEmail(forceLogin)
                handleSignInInputChange('email', forceLogin)
                handleSignUpInputChange('email', forceLogin.split('|')[0])
            }
            // monday account may be encoded as last param
            try {
                localStorage.setItem('mondayAccount', forceLogin.split('|')[2])
            } catch {}

            localStorage.removeItem('forceLogin')
        }

        // filledLogin is a query param that forces the user to login as a certain user email and password which we pass in (e.g. from our web form when we auto-create a user account); it may be in a query or might have been set in local storage
        let filledLogin = searchParams.get('filledLogin') || localStorage.getItem('filledLogin')

        // e.g. gabe@scoop.report|d****|ScoopWebsite|61cb586e-307a-4dd5-99be-044c8aba5ab3|W77|Gabe Test Samples|C200
        if (filledLogin) {
            filledLogin = decodeURIComponent(filledLogin)
            if (filledLogin.includes('|')) {
                setForceUserEmail(filledLogin.split('|')[0])
                setForcePassword(filledLogin.split('|')[1])
                handleSignInInputChange('email', filledLogin.split('|')[0])
                handleSignInInputChange('password', filledLogin.split('|')[1])
            } else {
                setForceUserEmail(filledLogin)
                handleSignInInputChange('email', filledLogin)
            }
            try {
                localStorage.setItem(
                    'lastUsedWorkspaces',
                    JSON.stringify({
                        dataType: 'Map',
                        value: [
                            [
                                filledLogin.split('|')[3],
                                [filledLogin.split('|')[4], filledLogin.split('|')[5]],
                            ],
                        ],
                    })
                )
            } catch {}
            try {
                localStorage.setItem('lastCanvas', filledLogin.split('|')[6])
            } catch {}
            try {
                localStorage.setItem('origin', filledLogin.split('|')[2])
            } catch {}

            localStorage.removeItem('filledLogin')
        }

        if (recipeMapping) {
            localStorage.setItem('recipeMapping', recipeMapping)
            logEvent('recipeProductInvocation', {
                type: 'attemptProductFromRecipe',
                recipe: {
                    name: recipeMapping,
                },
            })
        }

        if (isAuthenticated && isLogin) navigate(ROUTES.CANVAS_DASHBOARD)
    }, [isAuthenticated, isLogin])

    const signInWithGoogle = () => {
        try {
            window.dataLayer.push({ event: 'google_login_attempt' })
        } catch (e) {}
        federatedSignIn()
    }

    const selectOption = (option) => {
        if (option === 0) {
            handleGoToCanvas()
        } else if (option === 1) {
            navigate(ROUTES.RECIPE)
        }
    }

    const renderStep = () => {
        switch (step) {
            case 0:
                return renderSignUp()
            case 1:
                return renderVerifyEmail()
            case 2:
                return renderRecoverPassword()
            default:
                return renderSignUp() //Should never reach here
        }
    }

    const handleButtonClick = () => {
        if (isLogin) handleSignIn()
        else handleSignUp()
    }

    const renderSignUp = () => {
        return (
            <Box sx={{ width: '65%' }}>
                <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    cookieName="cookiesAccepted"
                    style={{
                        background: 'rgba(255, 255, 255, 0.90)',
                        color: '#222',
                        boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)', // Light grey shading above the banner
                    }}
                    buttonStyle={{ background: '#e50b54', color: '#FFFFFF', fontSize: '13px' }}
                    expires={365}
                >
                    We use cookies to ensure you have the best experience on our site.{'        '}
                    <span style={{ fontSize: '10px' }}>
                        <a
                            href="https://www.scoopanalytics.com/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#e50b54', textDecoration: 'underline' }} // Hyperlink styling
                        >
                            Learn more
                        </a>
                    </span>
                </CookieConsent>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '32px',
                        paddingBottom: '40px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img alt={'ScoopLogo'} src={ScoopLogo} />
                    </Box>
                    <Typography
                        sx={{
                            color: blackColor,
                            fontFamily: 'Inter',
                            textAlign: 'center',
                            fontSize: '32px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '110%',
                            letterSpacing: '-0.64px',
                        }}
                    >
                        {!isLogin ? 'Start your free trial' : 'Login to Scoop'}
                        {!isLogin && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    marginTop: '8px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '4px',
                                    }}
                                >
                                    <img style={{ width: '24px' }} alt={'check'} src={CheckGreen} />
                                    Free 30-day trial
                                </Typography>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '4px',
                                    }}
                                >
                                    <img style={{ width: '24px' }} alt={'check'} src={CheckGreen} />
                                    No credit card required
                                </Typography>
                            </Box>
                        )}
                    </Typography>
                </Box>
                <Box
                    id={'login-input-container'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    {!isLogin && (
                        <Box
                            className={'loginInput-no-margin'}
                            sx={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <Input
                                type={'text'}
                                value={signUpUser?.fullName || ''}
                                onChange={(e) =>
                                    handleSignUpInputChange('fullName', e.target.value)
                                }
                                sx={{
                                    '& .MuiTextField-root input': {
                                        margin: '0 !important',
                                    },
                                }}
                                label={'Full name'}
                            />
                            {errors['fullName'] && (
                                <Typography sx={{ fontSize: '12px', color: 'red' }}>
                                    {errors['fullName']}
                                </Typography>
                            )}
                        </Box>
                    )}
                    <Box
                        className={'loginInput-no-margin'}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Input
                            name={'email-input'}
                            type={'email'}
                            disabled={forceUserEmail === null ? false : true} // disable email input if forceUserEmail is set
                            value={isLogin ? signInUser.email : signUpUser.email}
                            onChange={(e) =>
                                isLogin
                                    ? handleSignInInputChange('email', e.target.value)
                                    : handleSignUpInputChange('email', e.target.value)
                            }
                            sx={{
                                '& .MuiTextField-root input': {
                                    margin: '0 !important',
                                },
                            }}
                            label={'Business Email'}
                        />
                        {errors['email'] && (
                            <Typography sx={{ fontSize: '12px', color: 'red' }}>
                                {errors['email']}
                            </Typography>
                        )}
                    </Box>
                    <Box
                        className={'loginInput-no-margin'}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Input
                            name={'password-input'}
                            type="password"
                            value={isLogin ? signInUser.password : signUpUser.password}
                            onChange={(e) =>
                                isLogin
                                    ? handleSignInInputChange('password', e.target.value)
                                    : handleSignUpInputChange('password', e.target.value)
                            }
                            sx={{
                                '& .MuiTextField-root input': {
                                    margin: '0 !important',
                                },
                            }}
                            label={'Password'}
                        />
                        {errors['password'] && (
                            <Typography sx={{ fontSize: '12px', color: 'red' }}>
                                {errors['password']}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Button
                            name={'login-button'}
                            onClick={handleButtonClick}
                            sx={{ marginTop: '24px' }}
                            className={'button-purple small'}
                            fullWidth
                            disabled={isSignUpLoading || isSignInLoading}
                        >
                            {isSignUpLoading || isSignInLoading ? (
                                <ScoopLoader />
                            ) : !isLogin ? (
                                'Create account'
                            ) : (
                                'Login'
                            )}
                        </Button>
                    </Box>
                    {/* disable google signin if product launched with forced user email (monday usecase) */}
                    {!forceUserEmail && (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                    sx={{
                                        width: '45.6%',
                                        borderBottom: '1px solid #E6E4E6',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        padding: '0 12px',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: blackColor,
                                    }}
                                >
                                    or
                                </Typography>
                                <Box
                                    sx={{
                                        width: '45.6%',
                                        borderBottom: '1px solid #E6E4E6',
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                }}
                            >
                                <Button
                                    onClick={signInWithGoogle}
                                    className={'button-grey small'}
                                    fullWidth
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '8px',
                                        }}
                                    >
                                        <img alt={'GoogleIcon'} src={GoogleIcon} />
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                color: blackColor,
                                            }}
                                        >
                                            Login with Google
                                        </Typography>
                                    </Box>
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        marginTop: '40px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 400,
                            color: blackColor,
                        }}
                    >
                        {isLogin ? 'Do not have an account?' : 'Already have a Scoop account?'}
                        <span
                            onClick={() => {
                                setIsLogin(!isLogin)
                                setErrors({
                                    fullName: '',
                                    email: '',
                                    password: '',
                                })
                            }}
                            style={{ color: redColor, cursor: 'pointer' }}
                        >
                            {isLogin ? ' Sign up here' : ' Login here'}
                        </span>
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 400,
                            color: blackColor,
                        }}
                    >
                        Forgot your password?
                        <span
                            onClick={() => setStep(2)}
                            style={{ color: redColor, cursor: 'pointer' }}
                        >
                            {' Recover it here'}
                        </span>
                    </Typography>
                </Box>
            </Box>
        )
    }

    const renderVerifyEmail = () => {
        return (
            <Box sx={{ width: '440px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '32px',
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                position: 'relative',
                                left: '7px',
                                top: '48px',
                                background:
                                    'linear-gradient(180deg, rgba(65, 39, 71, 0.00) 0%, rgba(65, 39, 71, 0.15) 100%)',
                                width: '98px',
                                height: '98px',
                                zIndex: -10,
                            }}
                        />
                        <img alt={'email-icon'} src={LoginEmailIcon} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                color: blackColor,
                                fontSize: '28px',
                                fontWeight: '400',
                                lineHeight: '110%',
                                letterSpacing: '-0.56px',
                            }}
                        >
                            Verify your email
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                color: '#4A3C4E',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '110%',
                                letterSpacing: '-0.28px',
                            }}
                        >
                            We sent a code to{' '}
                            <span style={{ fontWeight: 'bold' }}>{signUpUser.email}</span>
                            <br />
                            Enter below to verify your account
                        </Typography>
                    </Box>
                    <Box
                        className={'loginInput-no-margin'}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        <Input
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            padding={'12px'}
                            fullWidth
                            placeholder={'Enter 6 digit code'}
                        />
                        <Button
                            disabled={
                                verificationCode === null ||
                                verificationCode.length !== 6 ||
                                isSignUpLoading
                            }
                            onClick={() => handleConfirmSignup(verificationCode)}
                            fullWidth
                            className={'button-purple small'}
                        >
                            {isSignUpLoading ? <ScoopLoader /> : 'Submit code'}
                        </Button>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                color: '#4A3C4E',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '110%',
                                letterSpacing: '-0.24px',
                            }}
                        >
                            Don't see an email?
                            <br />
                            Check your spam folder or
                            <span style={{ color: redColor, cursor: 'pointer' }}>
                                {' '}
                                request new code
                            </span>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    }

    const renderOrganization = () => {
        return (
            <Box sx={{ width: '440px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '48px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '32px',
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    position: 'relative',
                                    left: '7px',
                                    top: '48px',
                                    background:
                                        'linear-gradient(180deg, rgba(65, 39, 71, 0.00) 0%, rgba(65, 39, 71, 0.15) 100%)',
                                    width: '98px',
                                    height: '98px',
                                    zIndex: -10,
                                }}
                            />
                            <img alt={'organization-icon'} src={OrganizationIcon} />
                        </Box>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                color: blackColor,
                                fontSize: '32px',
                                fontWeight: '400',
                                lineHeight: '110%',
                                letterSpacing: '-0.56px',
                            }}
                        >
                            Let’s set up your Organisation
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '16px',
                            width: '100%',
                        }}
                    >
                        <Box
                            className={'loginInput-no-margin'}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                            }}
                        >
                            <Input
                                label={'Organisation name'}
                                padding={'8px'}
                                fullWidth
                                placeholder={'Your business'}
                            />
                        </Box>
                        <Box
                            className={'loginInput-no-margin'}
                            sx={{ width: '100%', display: 'flex', gap: '8px' }}
                        >
                            <Input
                                label={'Invite members (optional)'}
                                padding={'8px'}
                                fullWidth
                                placeholder={'Add email'}
                            />
                            <Box
                                sx={{
                                    minHeight: '100%',
                                    display: 'flex',
                                    alignItems: 'end',
                                }}
                            >
                                <Box
                                    sx={{
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                        background: '#F2F2F2',
                                        display: 'flex',
                                        height: '43px',
                                        width: '43px',
                                        padding: '10px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                        float: 'bottom',
                                    }}
                                >
                                    <img alt={'sum-icon'} src={PlusIcon} />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                                padding: '12px',
                                backgroundColor: '#F9F9F9',
                                borderRadius: '8px',
                                maxHeight: '140px',
                                overflowY: 'scroll',
                            }}
                        >
                            {emails.map((email, index) => (
                                <EmailRow key={index} email={email} />
                            ))}
                        </Box>
                    </Box>
                    <Button fullWidth className={'button-purple small'}>
                        Set up and continue
                    </Button>
                </Box>
            </Box>
        )
    }

    const renderRecoverPassword = () => {
        return (
            <Box sx={{ width: '440px' }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '32px',
                        paddingBottom: '40px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img alt={'ScoopLogo'} src={ScoopLogo} />
                    </Box>
                    <Typography
                        sx={{
                            color: blackColor,
                            fontFamily: 'Inter',
                            textAlign: 'center',
                            fontSize: '32px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '110%',
                            letterSpacing: '-0.64px',
                        }}
                    >
                        Recover your password
                    </Typography>
                </Box>
                {recoverPasswordStep === 0 && (
                    <>
                        <Box
                            id={'login-input-container'}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                            }}
                        >
                            <Box
                                className={'loginInput-no-margin'}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Input
                                    type={'email'}
                                    value={email}
                                    onChange={(e) => handleEmailChange(e.target.value)}
                                    sx={{
                                        '& .MuiTextField-root input': {
                                            margin: '0 !important',
                                        },
                                    }}
                                    label={'Email'}
                                />
                            </Box>
                        </Box>
                    </>
                )}
                {recoverPasswordStep === 1 && (
                    <>
                        <Box
                            id={'login-input-container'}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                            }}
                        >
                            <Box
                                className={'loginInput-no-margin'}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Input
                                    type={'text'}
                                    value={newPassword.code}
                                    onChange={(e) =>
                                        handleRecoverPasswordChange('code', e.target.value)
                                    }
                                    sx={{
                                        '& .MuiTextField-root input': {
                                            margin: '0 !important',
                                        },
                                    }}
                                    label={'Verification Code'}
                                />
                            </Box>
                            <Box
                                className={'loginInput-no-margin'}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Input
                                    type={'password'}
                                    value={newPassword.password}
                                    onChange={(e) =>
                                        handleRecoverPasswordChange('password', e.target.value)
                                    }
                                    sx={{
                                        '& .MuiTextField-root input': {
                                            margin: '0 !important',
                                        },
                                    }}
                                    label={'New Password'}
                                />
                            </Box>
                            <Box
                                className={'loginInput-no-margin'}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Input
                                    type="password"
                                    value={newPassword.confirmPassword}
                                    onChange={(e) =>
                                        handleRecoverPasswordChange(
                                            'confirmPassword',
                                            e.target.value
                                        )
                                    }
                                    sx={{
                                        '& .MuiTextField-root input': {
                                            margin: '0 !important',
                                        },
                                    }}
                                    label={'Confirm Password'}
                                />
                            </Box>
                        </Box>
                    </>
                )}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Button
                            onClick={() =>
                                recoverPasswordStep === 0
                                    ? handleStartPWDReset()
                                    : handleFinishPWDReset()
                            }
                            sx={{ marginTop: '24px' }}
                            className={'button-purple small'}
                            fullWidth
                            disabled={
                                recoverPasswordStep === 0
                                    ? !email
                                    : !newPassword.code ||
                                      !newPassword.password ||
                                      !newPassword.confirmPassword
                            }
                        >
                            {isSignUpLoading || isSignInLoading ? (
                                <ScoopLoader />
                            ) : recoverPasswordStep === 0 ? (
                                'Send email'
                            ) : (
                                'Recover Password'
                            )}
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        marginTop: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 400,
                            color: blackColor,
                        }}
                    >
                        Already have a Scoop account?
                        <span
                            onClick={() => {
                                setStep(0)
                                setIsLogin(true)
                            }}
                            style={{ color: redColor, cursor: 'pointer' }}
                        >
                            {' Login here'}
                        </span>
                    </Typography>
                </Box>
            </Box>
        )
    }

    return step < 3 ? (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                maxWidth: '100vw',
                maxHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {renderStep()}
                <Box sx={{ position: 'absolute', left: '16px', top: '12px' }}>
                    <img alt={'SOC'} src={SOC} style={{ maxWidth: '86px', height: 'auto' }} />
                </Box>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: '5%',
                        width: '40%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1,
                        bgcolor: 'background.paper',
                    }}
                >
                    <Tooltip title="Watch a demo video of Scoop in use">
                        <Link
                            href="https://www.scoopanalytics.com/demo-video"
                            target="_blank"
                            sx={{
                                textDecoration: 'none',
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            Demo
                        </Link>
                    </Tooltip>
                    <Tooltip title="Read about Scoop and what it can do for you">
                        <Link
                            href="https://docs.scoopanalytics.com/docs/what-is-scoop"
                            target="_blank"
                            sx={{
                                textDecoration: 'none',
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            What is Scoop
                        </Link>
                    </Tooltip>
                    <Tooltip title="Read about how to get started with Scoop">
                        <Link
                            href="https://docs.scoopanalytics.com/docs/scoop-quickstart-guide"
                            target="_blank"
                            sx={{
                                textDecoration: 'none',
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            Getting Started
                        </Link>
                    </Tooltip>
                    <Tooltip title="Scoop's website">
                        <Link
                            href="https://www.scoopanalytics.com"
                            target="_blank"
                            sx={{
                                textDecoration: 'none',
                                fontSize: '14px',
                                color: 'text.primary',
                            }}
                        >
                            Home
                        </Link>
                    </Tooltip>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '50%',
                    height: '100%',
                    backgroundColor: '#130417',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '32px',
                    padding: '32px',
                }}
            >
                <img style={{ width: '100px' }} src={Scoop} alt={'Scoop'} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '750px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '25px',
                            lineHeight: '36px',
                            color: '#fff',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                        }}
                    >
                        Take control of your data within{' '}
                        <span style={{ backgroundColor: '#4763F5' }}> minutes</span>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img style={{ maxWidth: '360px' }} src={ScoopLogin} alt={'Scoop image'} />
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#fff',
                            textAlign: 'center',
                        }}
                    >
                        Create several canvases to track and present on your business health
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexDirection: 'column',
                        gap: '8px',
                        display: { xs: 'none', lg: 'flex' },
                    }}
                >
                    {features.map((feature, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#FFFFFF',
                                    fontSize: '12px',
                                    fontFamily: 'Inter',
                                }}
                            >
                                • {feature.title}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#AAAAAA',
                                    flex: 1,
                                    fontSize: '12px',
                                    fontFamily: 'Inter',
                                }}
                            >{`— ${feature.description}`}</Typography>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '600px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '25px',
                            lineHeight: '36px',
                            color: '#fff',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                        }}
                    >
                        Partnering with and{' '}
                        <span style={{ backgroundColor: '#4763F5' }}> trusted by:</span>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '650px',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        overflow: 'hidden',
                    }}
                >
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        infiniteLoop
                        autoPlay
                        interval={2000}
                        transitionTime={500}
                        emulateTouch
                        centerMode
                        centerSlidePercentage={10}
                        swipeable={false}
                    >
                        {logos.map((logo, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 'auto',
                                }}
                            >
                                <img
                                    src={logo.src}
                                    alt={logo.alt || ''}
                                    style={{
                                        alignSelf: 'center',
                                        maxWidth: '100px',
                                        width: 'auto',
                                        height: '100%',
                                        maxHeight: '50px',
                                    }}
                                />
                            </div>
                        ))}
                    </Carousel>
                </Box>
            </Box>
            <Snackbar
                open={!!showSnackbar}
                autoHideDuration={5000}
                onClose={() => setShowSnackbar(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={() => setShowSnackbar(null)}
                    severity={showSnackbar?.severity}
                    variant="filled"
                >
                    {showSnackbar?.msg}
                </Alert>
            </Snackbar>
        </Box>
    ) : (
        <Box
            sx={{
                height: '100vh',
                maxHeight: '100vh',
                width: '100vw',
                maxWidth: '100vw',
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 64px 64px 64px',
            }}
        >
            <RecipeNavbar goBack={() => setStep(step - 1)} showIcon={true} />
            <CreateWorkspace selectOption={selectOption} />
        </Box>
    )
}

const EmailRow = ({ email }) => {
    const initialLetter = email.charAt(0).toUpperCase()

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: '8px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '24px',
                        height: '24px',
                        padding: '2px 7px',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderRadius: '5px',
                        border: '1px solid rgba(164, 193, 252, 0.20)',
                        background: '#E8EFFC',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#134CBF',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '125%',
                            letterSpacing: '-0.32px',
                        }}
                    >
                        {initialLetter}
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        color: blackColor,
                    }}
                >
                    {email}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: '8px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <img alt={'trash-icon'} src={Trash} />
            </Box>
        </Box>
    )
}
