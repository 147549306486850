import React, { useState } from 'react'
import { SelectType } from '../SelectType'
import BlendingMethodDialog from './BlendingMethodDialog'
import AggregatePath from './AggregatePath'
import BlendedPath from './BlendedPath'
import { ServerContext } from '../../../../Source/SheetState'
import { Server } from '../../../../../api/Server'
import { useDispatch, useSelector } from 'react-redux'
import { Toast } from '../../../../common/Toast/Toast'
import { useWorkspaceMetadata } from '../../../../../api/hooks/workspaceMetadata/useWorkspaceMetadata'
import { logEvent } from '../../../../../api/hooks/logs/api'
import { setOnboardingChecklist } from '../../../../../store/actions/uiActions'
import userflow from 'userflow.js'

const BlendingDatasets = ({ onClose, stepBack }) => {
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const token = useSelector((state) => state.auth.token)

    const [step, setStep] = useState(1)
    const [type, setType] = useState('')
    const [inboxID, setInboxID] = useState(null)
    const [worksheetID, setWorksheetID] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [inboxName, setInboxName] = useState('')
    const [inboxDescription, setInboxDescription] = useState(null)
    const [alert, setAlert] = useState(null)
    const onboardingChecklist = useSelector((state) => state.ui.onboardingChecklist)
    const dispatch = useDispatch()

    const serverContext = new ServerContext(new Server(workspaceID, userID, token))
    const { workspaceMetadata: metadata } = useWorkspaceMetadata(userID, workspaceID, token)

    const handleCreateCSW = async (results) => {
        if (results.error || results.message === 'Internal server error') {
            setIsLoading(false)
            setAlert({
                message: 'An error occurred while creating the dataset.',
                severity: 'error',
            })
        } else {
            setInboxID(results.inboxID)
            setWorksheetID(results.worksheetID)
            setIsLoading(false)
            setStep(2)
        }
    }

    const handleCreateInbox = async (inboxName, sourceType) => {
        setIsLoading(true)
        setInboxName(inboxName)
        await serverContext.server.sheetPostData(
            {
                action: 'createCalculatedSheet',
                inboxName: inboxName,
                description: inboxDescription ? inboxDescription : undefined,
                inboxType: 'calculated',
                //"sourceType" : { sourceType: sourceType.toLowerCase() } LEAVE THIS FOR THE FUTURE, RIGHT NOW ASSUME IT'S TRANSACTIONAL
                sourceType: { sourceType: 'transactional' },
            },
            (results) => handleCreateCSW(results)
        )
    }

    const handleSave = async () => {
        try {
            await serverContext.server.sheetPostData(
                {
                    action: 'flushWorksheetCache',
                    sheetRange: {
                        inboxID: inboxID,
                    },
                },
                () => {
                    try {
                        userflow.track('blended_datasets', {
                            inboxID: `${inboxID}`,
                            inboxName: inboxName,
                        })
                        // console.log('sent userflow event', 'blended_datasets', {
                        //     inboxID: `${inboxID}`,
                        //     inboxName: inboxName,
                        // })
                    } catch (error) {}
                    //  GJ Jan 23: Commented out because UserFlow is supposed to take over the onboarding checklist
                    // if (onboardingChecklist === '0') dispatch(setOnboardingChecklist('1'))
                }
            )
        } catch (e) {
            console.error(e)
        }
    }

    const handleClose = async () => {
        /*if (inboxID) {
            await serverContext.server.postData({
                "action": "clearWorksheetCache",
                "sheetRange": {
                    "inboxID": inboxID
                }
            }, () => {
            });
        }*/
        onClose()
    }

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <SelectType
                        index={1}
                        stepBack={stepBack}
                        setType={setType}
                        onClose={handleClose}
                        isSnapshotDefault={true}
                        createInbox={true}
                        setStep={setStep}
                        handleCreateInbox={handleCreateInbox}
                        isLoading={isLoading}
                        isBlending={true}
                        setDescription={setInboxDescription}
                        continueButton={'Continue'}
                    />
                )
            case 2:
                return <BlendingMethodDialog setStep={setStep} onClose={handleClose} />
            case 3:
                return (
                    <BlendedPath
                        serverContext={serverContext}
                        metadata={metadata}
                        handleSave={handleSave}
                        inboxName={inboxName}
                        goBack={() => setStep(2)}
                        setStep={setStep}
                        onClose={handleClose}
                        inboxID={inboxID}
                        worksheetID={worksheetID}
                    />
                )
            case 4:
                return (
                    <AggregatePath
                        serverContext={serverContext}
                        metadata={metadata}
                        handleSave={handleSave}
                        inboxName={inboxName}
                        goBack={() => setStep(2)}
                        setStep={setStep}
                        onClose={onClose}
                        inboxID={inboxID}
                        worksheetID={worksheetID}
                    />
                )
            default:
                return <></>
        }
    }

    return (
        <>
            {renderStepContent()}
            <Toast alert={alert} onClose={() => setAlert(null)}></Toast>
        </>
    )
}

export default BlendingDatasets
