import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { signOut } from '../../api/auth'
import { setLastUsedWorkspaces } from '../../utils'
import { logout } from '../../store/actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import UserCircleGrey from '../../assets/icons/UserCircleGrey.svg'
import RocketLaunch from '../../assets/icons/RocketLaunch.svg'
import BellRinging from '../../assets/icons/BellRinging.svg'
import GearBlack from '../../assets/icons/GearBlack.svg'
import SignOut from '../../assets/icons/SignOut.svg'
import Divider from '@mui/material/Divider'
import { useUserInfo } from '../../api/hooks/user/useUserInfo'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../router/routes'

export const AccountDropdown = ({ anchorEl, isMenuOpen, setAnchorEl, workspaceName }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)
    const userEmail = useSelector((state) => state.auth.userEmail)

    const { user } = useUserInfo()

    const handleLogout = () => {
        signOut().then(() => {
            setLastUsedWorkspaces(userID, workspaceID, workspaceName)
            dispatch(logout())
        })
    }

    const handleSettings = () => {
        setAnchorEl(null)
        navigate(ROUTES.ACCOUNT)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{ top: 40 }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '0 8px 8px 8px',
                    position: 'relative',
                }}
            >
                <Typography
                    className={'inter'}
                    sx={{
                        fontSize: '12px',
                        color: '#979099',
                        textAlign: 'center',
                        position: 'absolute',
                        top: '-6px',
                        right: '3px',
                    }}
                >
                    {process.env.REACT_APP_PRODUCT_VERSION}
                </Typography>
                <img
                    src={UserCircleGrey}
                    alt={'UserCircle'}
                    style={{ height: 40, marginRight: '8px' }}
                />
                <Box classname={'user-info-container'}>
                    <Typography className={'inter'} sx={{ fontWeight: 600 }}>
                        {user.firstName + ' ' + user.lastName}
                    </Typography>
                    <Typography className={'inter'} sx={{ color: '#979099', fontSize: '14px' }}>
                        {userEmail}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <MenuItem disabled onClick={handleSettings}>
                <img src={GearBlack} alt={'GearBlack'} style={{ marginRight: '8px' }} />
                <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                    Settings (Coming soon!)
                </Typography>
            </MenuItem>
            {/*<MenuItem disabled>*/}
            {/*    <img src={BellRinging} alt={'BellRinging'} style={{ marginRight: '8px' }} />*/}
            {/*    <Typography className={'inter'} sx={{ fontSize: '14px' }}>*/}
            {/*        Notification preferences*/}
            {/*    </Typography>*/}
            {/*</MenuItem>*/}
            <MenuItem onClick={handleLogout}>
                <img src={SignOut} alt={'SignOut'} style={{ marginRight: '8px' }} />
                <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                    Log out
                </Typography>
            </MenuItem>
            {/*<Box*/}
            {/*    sx={{*/}
            {/*        display: 'flex',*/}
            {/*        flexDirection: 'row',*/}
            {/*        padding: '12px',*/}
            {/*        margin: '8px 8px 0 8px',*/}
            {/*        backgroundColor: '#FEF4F7',*/}
            {/*        border: '1px solid #FCE7EE',*/}
            {/*        borderRadius: '3px',*/}
            {/*        cursor: 'pointer',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <img src={RocketLaunch} alt={'RocketLaunch'} style={{ marginRight: '8px' }} />*/}
            {/*    <Typography className={'inter'} sx={{ fontSize: '14px', color: '#E50B54' }}>*/}
            {/*        Upgrade to Premium account*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
        </Menu>
    )
}
