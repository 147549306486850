import React from 'react'
import './Account.css'
import { Box, Typography } from '@mui/material'

export const Account = () => {
    return (
        <Box className={'account-screen-container'}>
            <Typography className={'account-title-container'}>Account</Typography>
        </Box>
    )
}
