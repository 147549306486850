import ReactECharts from 'echarts-for-react'
import { ScoopTheme } from './Style'
import * as React from 'react'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { ServerSideGrid } from './InsightsGrid/ServerSideGrid'
import _, { cloneDeep, isNumber } from 'lodash'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import Typography from '@mui/material/Typography'
import './Insight.css'
import CaretRight from '../../assets/icons/CaretRight.svg'
import CloseIcon from '../../assets/icons/CloseIcon.svg'
import CloseIconWhite from '../../assets/icons/CloseIconWhite.svg'
import { getDefaultChartPreferences } from '../screens/Explorer/DrawerTabs/Style/utils'
import {
    AXIS_DEFAULT_VALUES,
    AXIS_TEXT_DEFAULT_VALUES,
    BAR_DEFAULT_VALUES,
    GAUGE_DEFAULT_VALUES,
    LEGEND_DEFAULT_VALUES,
    PIE_DEFAULT_VALUES,
    RADIAL_DEFAULT_VALUES,
    TITLE_DEFAULT_VALUES,
} from '../screens/Explorer/DrawerTabs/Style/styleConsts'
import { KPI } from './KPI/KPI'
import OpenDrawer from '../../assets/icons/OpenDrawer.svg'

export function Insight({
    chartProperties,
    config,
    setConfig,
    embeddedSizeProps,
    server,
    clickable,
    advanced,
    analyzeChanges,
    handleCreateFilter,
    setColumnLoading,
    workspaceMetadata,
    activePrompts,
    dateFlag,
    theme,
    setDataTabOpen,
    dataTabOpen,
    setConfigTabOpen,
    configTabOpen,
}) {
    const [style, setStyle] = React.useState({})
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [seriesName, setSeriesName] = React.useState(null)
    const [drillColumn, setDrillColumn] = React.useState(null)
    const [drillingHistory, setDrillingHistory] = React.useState([])
    const [categoryValue, setCategoryValue] = React.useState(null)
    const openMenu = Boolean(anchorEl)

    const handleMenuClick = (event) => {
        setAnchorEl(event.event.event.currentTarget)
        if (config.view === 'chart') {
            setStyle({
                position: 'absolute',
                left: event.event.event.clientX + 'px',
                top: event.event.event.clientY + 'px',
                minHeight: 300 + 'px',
            })
        } else {
            setDrillColumn(event.drillColumn)
        }
        if (event.name) setCategoryValue(event.name)
        setSeriesName(event.seriesName)
    }

    const handleTableMenuClose = (event, col) => {
        setAnchorEl(null)
        if (event.key === 'Escape' || event.currentTarget.textContent === '') return
        let newFilter = {
            attributeName: drillColumn.columnName,
            operator: 'Equals',
            filterValue: {
                values: [seriesName],
            },
        }
        let curFilter = chartProperties.addFilterItem(newFilter)
        config.selectedTableColumns.push(col)
        config.filter = curFilter
        if (drillingHistory.length === 0) {
            setDrillingHistory([
                { attribute: drillColumn.columnName },
                {
                    attribute: event.currentTarget.textContent,
                    filter: newFilter,
                },
            ])
        } else {
            setDrillingHistory([
                ...drillingHistory,
                {
                    attribute: event.currentTarget.textContent,
                    filter: newFilter,
                },
            ])
        }
        setConfig({ ...config })
    }

    const handleChartMenuClose = (event) => {
        setAnchorEl(null)
        if (event.key === 'Escape' || event.currentTarget.textContent === '') return
        if (chartProperties.config.drillAttribute) {
            chartProperties.config.usedDrillAttributes.push(chartProperties.config.drillAttribute)
            let series = chartProperties.getSeries(seriesName)
            if (series) {
                let newFilter = {
                    attributeName: config.drillAttribute,
                    operator: 'Equals',
                    filterValue: {
                        values: [series.category],
                    },
                }
                let curFilter = chartProperties.addFilterItem(newFilter)
                config.drillAttribute = event.currentTarget.textContent
                config.filter = curFilter
                setDrillingHistory([
                    ...drillingHistory,
                    {
                        attribute: event.currentTarget.textContent,
                        filter: newFilter,
                    },
                ])
                chartProperties.getResults(config, null, activePrompts)
            }
        } else {
            config.drillAttribute = event.currentTarget.textContent
            const category = config.categoryAxis
            if (categoryValue && category && category !== 'Time') {
                let newFilter = {
                    attributeName: category,
                    operator: 'Equals',
                    filterValue: { values: [categoryValue] },
                }
                config.filter = chartProperties.addFilterItem(newFilter)
                setDrillingHistory([
                    ...drillingHistory,
                    {
                        attribute: event.currentTarget.textContent,
                        filter: newFilter,
                    },
                ])
                chartProperties.getResults(config, null, activePrompts)
            } else {
                setDrillingHistory([
                    ...drillingHistory,
                    { attribute: event.currentTarget.textContent },
                ])
                chartProperties.getResults(config, null, activePrompts)
            }
        }
        setConfig({ ...config })
    }

    function onChartClick(event) {
        handleMenuClick(event)
    }

    const onEvents = {
        click: config.seriesType !== 'pie' && config.seriesType !== 'donut' ? onChartClick : null,
    }

    function validChart() {
        if (config.view === 'kpi') return config.selectedItems.length > 0
        if (
            config.view === 'table' &&
            (config.selectedTableColumns.length > 0 || config.selectedTableKpis.length > 0)
        )
            return true
        if (
            chartProperties.config.seriesType === 'scatter' &&
            chartProperties.config.selectedItems &&
            chartProperties.config.selectedItems?.length !== 2
        )
            return false
        return (
            chartProperties.series &&
            chartProperties.series?.length > 0 &&
            ((chartProperties.result.dates && chartProperties.result.dates?.length > 0) ||
                chartProperties.series[0].data?.length > 0)
        )
    }

    const chartSetting = {
        height: embeddedSizeProps ? embeddedSizeProps.height : window.innerHeight - 112 - 64 + 'px',
        pointerEvents: clickable ? 'all' : 'none',
        margin: embeddedSizeProps ? 0 : '32px',
    }

    const getChartDrillItems = () => {
        let drillAtts = []
        if (chartProperties.drillAttributes?.length > 0) {
            drillAtts = chartProperties.drillAttributes?.map((item) => {
                if (
                    item === chartProperties.categoryAxis ||
                    item === chartProperties.config.drillAttribute ||
                    !chartProperties.getAvailableDrillAttributes().includes(item) ||
                    chartProperties.config.selectedTableColumns.includes(item)
                ) {
                    return null
                }
                return (
                    <MenuItem key={item} value={item} onClick={handleChartMenuClose}>
                        {item}
                    </MenuItem>
                )
            })
        }
        let changeDrillAtts = []
        if (advanced && analyzeChanges && chartProperties.changeDrillAttributes?.length > 0) {
            changeDrillAtts =
                advanced &&
                analyzeChanges &&
                chartProperties.changeDrillAttributes.map((item) => {
                    if (
                        item === chartProperties.categoryAxis ||
                        item === chartProperties.config.drillAttribute
                    ) {
                        return null
                    }
                    return (
                        <MenuItem key={item} value={item} onClick={handleChartMenuClose}>
                            {item}
                        </MenuItem>
                    )
                })
        }
        return [...drillAtts, ...changeDrillAtts]
    }

    const getTableDrillItems = () => {
        const tables = []
        workspaceMetadata?.inboxes?.forEach((inbox) => {
            inbox.tables.forEach((table) => {
                if (config.selectedTables.includes(table.reportSeriesTableID)) tables.push(table)
            })
        })
        const columns = _.intersection(
            ...tables.map((table) =>
                table.columns.map((col) => ({
                    ...col,
                    reportSeriesTableID: table.reportSeriesTableID,
                }))
            )
        )
        if (!columns) {
            let a = 1
        }
        const availableDrills = columns.filter((column) => {
            if (!column) {
                let a = 1
            }
            return (
                !column.isMeasure &&
                !config.selectedTableColumns.some((col) => col.columnName === column.columnName)
            )
        })
        return availableDrills.map((col) => (
            <MenuItem key={col.columnName} onClick={(e) => handleTableMenuClose(e, col)}>
                {col.columnName}
            </MenuItem>
        ))
    }

    const handleDeleteDrillingStep = (step, i) => {
        const newConfig = { ...config }
        const newDrillingHistory = [...drillingHistory]
        if (config.view === 'chart') {
            if (drillingHistory.length === 1) {
                // empty drill attribute from config
                newConfig.drillAttribute = undefined
                // empty drill history
                setDrillingHistory([])
            } else {
                // move drill attribute
                newConfig.drillAttribute = newDrillingHistory[i - 1].attribute
                // remove step filter
                newConfig.filter = chartProperties.removeFilterItem(step.filter)
                // remove step from history
                newDrillingHistory.splice(i, 1)
                // enable drill attribute again
                newConfig.usedDrillAttributes.splice(
                    newConfig.usedDrillAttributes.indexOf(step.attribute),
                    1
                )
                setDrillingHistory(newDrillingHistory)
            }
            setConfig(newConfig)
            chartProperties.getResults(newConfig, null, activePrompts)
        } else {
            // remove column
            const deleteIndex = newConfig.selectedTableColumns.findIndex(
                (col) => col.columnName === step.attribute
            )
            newConfig.selectedTableColumns.splice(deleteIndex, 1)
            // remove step filter
            newConfig.filter = chartProperties.removeFilterItem(step.filter)
            if (drillingHistory.length === 2) {
                // empty drill history
                setDrillingHistory([])
            } else {
                // remove step from history
                newDrillingHistory.splice(i, 1)
                setDrillingHistory(newDrillingHistory)
            }
            setConfig(newConfig)
        }
    }

    const navigateToStep = (step, i) => {
        const newConfig = { ...config }
        let newDrillingHistory = [...drillingHistory]
        const toDeleteSteps = newDrillingHistory.slice(i + 1)
        if (config.view === 'chart') {
            toDeleteSteps.forEach((s) => {
                // remove filters from steps > selected step
                newConfig.filter = chartProperties.removeFilterItem(s.filter)
                // enable drill attribute again
                newConfig.usedDrillAttributes.splice(
                    newConfig.usedDrillAttributes.indexOf(s.attribute),
                    1
                )
            })
            // move drill attribute to selected step
            newConfig.drillAttribute = step.attribute
            // remove left steps > selected step
            newDrillingHistory = newDrillingHistory.slice(0, i + 1)
            setDrillingHistory(newDrillingHistory)
            setConfig(newConfig)
            chartProperties.getResults(newConfig, null, activePrompts)
        } else {
            toDeleteSteps.forEach((s) => {
                // remove column
                const deleteIndex = newConfig.selectedTableColumns.findIndex(
                    (col) => col.columnName === s.attribute
                )
                newConfig.selectedTableColumns.splice(deleteIndex, 1)
                // remove step filter
                newConfig.filter = chartProperties.removeFilterItem(s.filter)
            })
            // remove left steps > selected step
            newDrillingHistory = newDrillingHistory.slice(0, i + 1)
            setDrillingHistory(newDrillingHistory)
            setConfig(newConfig)
        }
    }

    const isStepClickable = (i) => {
        if (config.view === 'chart') return i < drillingHistory.length - 1
        else return i > 0 && i < drillingHistory.length - 1
    }

    const applyFontScale = (overrides, width, height) => {
        overrides.title.textStyle.fontSize =
            (((overrides.title.textStyle.fontSize ?? TITLE_DEFAULT_VALUES.textStyle.fontSize) /
                overrides.dimensions.width) *
                width +
                ((overrides.title.textStyle.fontSize || TITLE_DEFAULT_VALUES.textStyle.fontSize) /
                    overrides.dimensions.height) *
                    height) /
            2

        overrides.legend.textStyle.fontSize =
            (((overrides.legend.textStyle.fontSize || LEGEND_DEFAULT_VALUES.textStyle.fontSize) /
                overrides.dimensions.width) *
                width +
                ((overrides.legend.textStyle.fontSize || LEGEND_DEFAULT_VALUES.textStyle.fontSize) /
                    overrides.dimensions.height) *
                    height) /
            2

        overrides.legend.itemSize =
            (((overrides.legend.itemSize || LEGEND_DEFAULT_VALUES.itemSize) /
                overrides.dimensions.width) *
                width +
                ((overrides.legend.itemSize || LEGEND_DEFAULT_VALUES.itemSize) /
                    overrides.dimensions.height) *
                    height) /
            2

        overrides.pie.label.fontSize =
            (((overrides.pie.label.fontSize || PIE_DEFAULT_VALUES.label.fontSize) /
                overrides.dimensions.width) *
                width +
                ((overrides.pie.label.fontSize || PIE_DEFAULT_VALUES.label.fontSize) /
                    overrides.dimensions.height) *
                    height) /
            2

        overrides.donut.label.fontSize =
            (((overrides.donut.label.fontSize || PIE_DEFAULT_VALUES.label.fontSize) /
                overrides.dimensions.width) *
                width +
                ((overrides.donut.label.fontSize || PIE_DEFAULT_VALUES.label.fontSize) /
                    overrides.dimensions.height) *
                    height) /
            2

        overrides.radialBar.radiusAxis.axisLabel.fontSize =
            (((overrides.radialBar.radiusAxis.axisLabel.fontSize ||
                RADIAL_DEFAULT_VALUES.radiusAxis.axisLabel.fontSize) /
                overrides.dimensions.width) *
                width +
                ((overrides.radialBar.radiusAxis.axisLabel.fontSize ||
                    RADIAL_DEFAULT_VALUES.radiusAxis.axisLabel.fontSize) /
                    overrides.dimensions.height) *
                    height) /
            2

        overrides.radialBar.label.fontSize =
            (((overrides.radialBar.label.fontSize || RADIAL_DEFAULT_VALUES.label.fontSize) /
                overrides.dimensions.width) *
                width +
                ((overrides.radialBar.label.fontSize || RADIAL_DEFAULT_VALUES.label.fontSize) /
                    overrides.dimensions.height) *
                    height) /
            2

        overrides.gauge.detail.fontSize =
            (((overrides.gauge.detail.fontSize || GAUGE_DEFAULT_VALUES.detail.fontSize) /
                overrides.dimensions.width) *
                width +
                ((overrides.gauge.detail.fontSize || GAUGE_DEFAULT_VALUES.detail.fontSize) /
                    overrides.dimensions.height) *
                    height) /
            2

        overrides.gauge.title.fontSize =
            (((overrides.gauge.title.fontSize || GAUGE_DEFAULT_VALUES.title.fontSize) /
                overrides.dimensions.width) *
                width +
                ((overrides.gauge.title.fontSize || GAUGE_DEFAULT_VALUES.title.fontSize) /
                    overrides.dimensions.height) *
                    height) /
            2

        overrides.gauge.axisLabel.fontSize =
            (((overrides.gauge.axisLabel.fontSize || GAUGE_DEFAULT_VALUES.axisLabel.fontSize) /
                overrides.dimensions.width) *
                width +
                ((overrides.gauge.axisLabel.fontSize || GAUGE_DEFAULT_VALUES.axisLabel.fontSize) /
                    overrides.dimensions.height) *
                    height) /
            2

        overrides.xAxis.forEach((axis) => {
            axis.axisLabel.fontSize =
                (((axis.axisLabel.fontSize || AXIS_TEXT_DEFAULT_VALUES.fontSize) /
                    overrides.dimensions.width) *
                    width +
                    ((axis.axisLabel.fontSize || AXIS_TEXT_DEFAULT_VALUES.fontSize) /
                        overrides.dimensions.height) *
                        height) /
                2

            axis.nameTextStyle.fontSize =
                (((axis.nameTextStyle.fontSize || AXIS_TEXT_DEFAULT_VALUES.fontSize) /
                    overrides.dimensions.width) *
                    width +
                    ((axis.nameTextStyle.fontSize || AXIS_TEXT_DEFAULT_VALUES.fontSize) /
                        overrides.dimensions.height) *
                        height) /
                2
        })

        overrides.yAxis.forEach((axis) => {
            axis.axisLabel.fontSize =
                (((axis.axisLabel.fontSize || AXIS_TEXT_DEFAULT_VALUES.fontSize) /
                    overrides.dimensions.width) *
                    width +
                    ((axis.axisLabel.fontSize || AXIS_TEXT_DEFAULT_VALUES.fontSize) /
                        overrides.dimensions.height) *
                        height) /
                2

            axis.nameTextStyle.fontSize =
                (((axis.nameTextStyle.fontSize || AXIS_TEXT_DEFAULT_VALUES.fontSize) /
                    overrides.dimensions.width) *
                    width +
                    ((axis.nameTextStyle.fontSize || AXIS_TEXT_DEFAULT_VALUES.fontSize) /
                        overrides.dimensions.height) *
                        height) /
                2
        })
    }

    const applyLegendIconSizeTransformation = (option) => {
        const size = option.legend.itemSize || 10
        option.legend.itemWidth = size
        option.legend.itemHeight = size
        delete option.legend.itemSize
    }

    const ensureAxisOverrides = (optionAxis, overridesAxis, referenceAxis) => {
        if (optionAxis.length > overridesAxis.length) {
            optionAxis.forEach((_, i) => {
                overridesAxis[i] = overridesAxis[i] || { ...referenceAxis[0] }
            })
        }
    }

    const dataContainsNegatives = (option) => {
        return option.series.some((s) =>
            s.data.some((d) => {
                if (typeof d === 'number') {
                    return d < 0
                } else if (d && Array.isArray(d) && d.length > 1) {
                    return d[1] < 0
                } else if (typeof d === 'object' && d !== null) {
                    return d.value < 0
                }
                return false
            })
        )
    }

    const applyColumnResize = (option, overrides) => {
        if (
            (config.seriesType === 'bar' || config.seriesType === 'column') &&
            chartProperties.categoryLegendData?.length > 1
        ) {
            let divisor = 1
            if (chartProperties.categoryAxisValues > 1 && !config.stacked)
                divisor = chartProperties.categoryAxisValues?.length - 1
            if (chartProperties.categoryLegendData && !config.stacked)
                divisor = divisor + chartProperties.categoryLegendData?.length - 1
            if (config.selectedItems.length > 1 && config.stacked) {
                const uniqueSeries = _.uniqBy(config.selectedItems, 'kpi')
                divisor = uniqueSeries.length
            }
            option.series.forEach((s, i) => {
                let barWidth =
                    (overrides.bar?.barWidth?.split('%')[0] || BAR_DEFAULT_VALUES.barWidth) /
                    divisor
                if (barWidth) {
                    option.series[i] = {
                        ...s,
                        ...overrides.bar,
                        barWidth: `${barWidth}%`,
                    }
                }
            })
        } else if (config.seriesTypeMap.size > 0) {
            const columnNames = Array.from(config.seriesTypeMap.entries())
                .filter(
                    ([columnName, columnType]) => columnType === 'bar' || columnType === 'column'
                )
                .map(([columnName]) => columnName)
            if (columnNames.length > 0) {
                const divisor = columnNames.length
                option.series.forEach((s, i) => {
                    let barWidth =
                        (overrides.bar?.barWidth?.split('%')[0] || BAR_DEFAULT_VALUES.barWidth) /
                        divisor
                    if (barWidth && columnNames.includes(s.name)) {
                        option.series[i] = {
                            ...s,
                            ...overrides.bar,
                            barWidth: `${barWidth}%`,
                        }
                    }
                })
            }
        }
    }

    const adjustBorderRadius = (radius) => {
        const tempRadius = [...radius]
        return [tempRadius[2], tempRadius[0], tempRadius[1], tempRadius[3]]
    }

    const buildMap = (length, series) => {
        let lastValidIndicesMap = {}
        for (let i = 0; i < length; i++) {
            series.forEach((series, index) => {
                const value = series.data[i]
                const isValid = Array.isArray(value)
                    ? value[1] !== 0 && value[1] !== null
                    : value !== 0 && value !== null
                if (isValid) lastValidIndicesMap[i] = index
            })
        }
        return lastValidIndicesMap
    }

    const getOptionWithOverrides = () => {
        const defaultTheme = JSON.parse(getDefaultChartPreferences())
        let option = _.cloneDeep(chartProperties.getOption())
        if (theme) option = _.cloneDeep(chartProperties.getOption(theme.themeID, workspaceMetadata))
        let overrides = _.cloneDeep(config.styleOverrides)
        let baseTheme = JSON.parse(getDefaultChartPreferences())
        // Make sure that overrides axis length are up to date and match the option axis length
        if (config.seriesType === 'bar') {
            ensureAxisOverrides(option.yAxis, overrides.xAxis, overrides.xAxis)
            ensureAxisOverrides(option.xAxis, overrides.yAxis, overrides.yAxis)
        } else {
            ensureAxisOverrides(option.yAxis, overrides.yAxis, overrides.yAxis)
            ensureAxisOverrides(option.xAxis, overrides.xAxis, overrides.xAxis)
        }

        if (theme && theme.chartPreferences) {
            let preferences =
                typeof theme.chartPreferences === 'string'
                    ? JSON.parse(theme.chartPreferences)
                    : theme.chartPreferences
            //Convert to arrays for backwards compatibility
            if (!Array.isArray(preferences.yAxis))
                preferences.yAxis = cloneDeep([preferences.yAxis])
            if (!Array.isArray(preferences.xAxis))
                preferences.xAxis = cloneDeep([preferences.xAxis])
            // Apply preferences style to each of the axes.
            overrides.yAxis.forEach((axis, i) => {
                if (preferences.yAxis[i])
                    overrides.yAxis[i] = _.merge(
                        _.cloneDeep(preferences.yAxis[i]),
                        _.cloneDeep(axis)
                    )
                else
                    overrides.yAxis[i] = _.merge(
                        _.cloneDeep(preferences.yAxis[0]),
                        _.cloneDeep(axis)
                    )
            })
            overrides.xAxis.forEach((axis, i) => {
                if (preferences.xAxis[i])
                    overrides.xAxis[i] = _.merge(
                        _.cloneDeep(preferences.xAxis[i]),
                        _.cloneDeep(axis)
                    )
                else
                    overrides.xAxis[i] = _.merge(
                        _.cloneDeep(preferences.xAxis[0]),
                        _.cloneDeep(axis)
                    )
            })
            preferences = _.omit(preferences, 'xAxis')
            preferences = _.omit(preferences, 'yAxis')
            overrides = _.merge(_.cloneDeep(preferences), _.cloneDeep(overrides))
        } else {
            // Apply default theme to each of the axes.
            overrides.yAxis.forEach(
                (axis, i) =>
                    (overrides.yAxis[i] = _.merge(_.cloneDeep(baseTheme.yAxis), _.cloneDeep(axis)))
            )
            overrides.xAxis.forEach(
                (axis, i) =>
                    (overrides.xAxis[i] = _.merge(_.cloneDeep(baseTheme.xAxis), _.cloneDeep(axis)))
            )
            baseTheme = _.omit(baseTheme, 'xAxis')
            baseTheme = _.omit(baseTheme, 'yAxis')
            overrides = _.merge(_.cloneDeep(baseTheme), _.cloneDeep(overrides))
        }
        if (config.seriesType === 'bar') {
            let radius = overrides.bar?.itemStyle?.borderRadius || [5, 5, 0, 0]
            if (overrides.bar?.itemStyle?.borderRadius) {
                overrides.bar.itemStyle.borderRadius = adjustBorderRadius(radius)
            }
        }

        // remove position overrides if present (backwards comp)
        overrides.xAxis.forEach((a) => {
            if (a.position) delete a.position
        })
        overrides.yAxis.forEach((a) => {
            if (a.position) delete a.position
        })

        // remove axis for pie and donut
        if (
            config.seriesType === 'pie' ||
            config.seriesType === 'donut' ||
            config.seriesType === 'gauge' ||
            config.seriesType === 'radialBar'
        ) {
            overrides.xAxis.forEach((axis) => (axis.show = false))
            overrides.yAxis.forEach((axis) => (axis.show = false))
            overrides.legend.icon = 'none'
            option.tooltip.trigger = 'item'
            if (overrides.grid?.tooltip?.axisPointer) {
                overrides.grid.tooltip.trigger = 'item'
            }
        } else if (
            config.stacked &&
            (config.seriesType === 'bar' || config.seriesType === 'column')
        ) {
            option.tooltip.trigger = 'item'
            if (overrides.grid?.tooltip?.axisPointer) {
                overrides.grid.tooltip.trigger = 'item'
            }
        }

        overrides.yAxis.forEach((axis) => {
            if (axis.show === undefined) axis.show = AXIS_DEFAULT_VALUES.show
            if (axis.splitLine.show === undefined)
                axis.splitLine.show = AXIS_DEFAULT_VALUES.splitLine.show
            if (axis.splitLine.lineStyle.color === undefined)
                axis.splitLine.lineStyle.color = AXIS_DEFAULT_VALUES.splitLine.lineStyle.color
            if (axis.splitLine.lineStyle.type === undefined)
                axis.splitLine.lineStyle.type = AXIS_DEFAULT_VALUES.splitLine.lineStyle.type
            if (axis.splitLine.lineStyle.width === undefined)
                axis.splitLine.lineStyle.width = AXIS_DEFAULT_VALUES.splitLine.lineStyle.width
        })

        // apply pie/donut configs (this has to be done before the font scale)
        if (config.seriesType === 'donut') {
            overrides.donut = _.merge(
                JSON.parse(getDefaultChartPreferences()).donut,
                overrides.donut
            )
            option.series.forEach((s, i) => {
                option.series[i] = { ...s, ...overrides.donut }
            })
        } else {
            overrides.pie = _.merge(JSON.parse(getDefaultChartPreferences()).pie, overrides.pie)
            if (option.series.some((s) => s.type === 'pie')) {
                option.series.forEach((s, i) => {
                    option.series[i] = { ...s, ...overrides.pie }
                })
            }
        }

        // apply font scale
        if (embeddedSizeProps) {
            const height = embeddedSizeProps.containerSize.height
            const width = embeddedSizeProps.containerSize.width
            applyFontScale(overrides, width, height)
        }

        if (config.seriesType === 'bar') {
            // Distributing the overrides to xAxis and yAxis for bar charts
            option.xAxis.forEach((axisObject, i) => {
                option.xAxis[i] = { ...axisObject, ...overrides.yAxis[i] }
            })
            option.yAxis.forEach((axisObject, i) => {
                option.yAxis[i] = { ...axisObject, ...overrides.xAxis[i] }
            })
            if (option.series.some((s) => s.yAxisIndex !== undefined)) {
                option.series.forEach((s, i) => {
                    s.xAxisIndex = s.yAxisIndex
                    delete s.yAxisIndex
                })
            }
        } else {
            // Distributing the overrides to xAxis and yAxis for every other chart
            option.yAxis.forEach((axisObject, i) => {
                option.yAxis[i] = _.merge(axisObject, overrides.yAxis[i])
                if (['top', 'bottom'].includes(option.yAxis[i].position)) {
                    option.yAxis[i].position = ['left', 'right'][i] || 'right'
                    option.yAxis[i].axisLabel.align = ['left', 'right'].filter(
                        (o) => o !== option.yAxis[i].position
                    )[0]
                }
            })
            option.xAxis.forEach((axisObject, i) => {
                delete overrides.xAxis[i].name
                option.xAxis[i] = _.merge(axisObject, overrides.xAxis[i])
            })
            if (option.series.some((s) => s.xAxisIndex !== undefined)) {
                option.series.forEach((s, i) => {
                    s.yAxisIndex = s.xAxisIndex
                    delete s.xAxisIndex
                })
            }
        }

        // apply bar/waterfall configs
        if (config.seriesType === 'waterfall') {
            option.series.forEach((s, i) => {
                if (s.name === 'positive') {
                    option.series[i].itemStyle = {
                        color: overrides.waterfall?.upColor || defaultTheme.waterfall.upColor,
                    }
                    option.series[i].data[0] = {
                        ...option.series[i].data[0],
                        itemStyle: {
                            color:
                                overrides.waterfall.startColor || defaultTheme.waterfall.startColor,
                        },
                    }
                }
                if (s.name === 'negative') {
                    option.series[i].itemStyle = {
                        color: overrides.waterfall.downColor || defaultTheme.waterfall.downColor,
                    }
                    const lastIndex = option.series[i].data.length - 1
                    option.series[i].data[lastIndex] = {
                        ...option.series[i].data[lastIndex],
                        itemStyle: {
                            color: overrides.waterfall.endColor || defaultTheme.waterfall.endColor,
                        },
                    }
                }
            })
        } else {
            if (option.series.some((s) => s.type === 'bar')) {
                option.series.forEach((s, i) => {
                    option.series[i] = { ...s, ...overrides.bar }
                })
            }
        }

        // apply line configs
        if (option.series.some((s) => s.type === 'line')) {
            option.series.forEach((s, i) => {
                option.series[i] = { ...s, ...overrides.line }
            })
        }

        if (config.seriesType === 'radialBar') {
            overrides.radialBar = _.merge(
                JSON.parse(getDefaultChartPreferences()).radialBar,
                overrides.radialBar
            )
            option = {
                ...option,
                angleAxis: {
                    ...overrides.radialBar.angleAxis,
                    data: { ...chartProperties?.categoryAxisValues },
                },
                polar: overrides.radialBar.polar,
                radiusAxis: overrides.radialBar.radiusAxis,
            }
            const showAsStacked = overrides.radialBar.stack
            option.series.forEach((s, i) => {
                if (showAsStacked) option.series[i].stack = 'total'
                option.series[i].coordinateSystem = 'polar'
                option.series[i].type = 'bar'
                option.series[i].label = {
                    position: 'middle',
                    formatter: '{b}: {c}',
                    ...overrides.radialBar.label,
                }
                option.series[i] = {
                    ...s,
                    emphasis: overrides.radialBar.emphasis,
                    itemStyle: overrides.radialBar.itemStyle,
                    barWidth: overrides.radialBar.barWidth,
                }
            })
        }

        // apply pictorial configs
        if (config.seriesType === 'pictorialBar') {
            const xAxisCopy = _.cloneDeep(option.xAxis)
            const yAxisCopy = _.cloneDeep(option.yAxis)

            if (overrides.pictorialBar.showAsBar) {
                option.xAxis = yAxisCopy
                option.yAxis = xAxisCopy

                option.series.forEach((seriesItem) => {
                    if (seriesItem.yAxisIndex !== undefined) {
                        seriesItem.xAxisIndex = seriesItem.yAxisIndex
                        delete seriesItem.yAxisIndex
                    }
                })
            } else {
                option.xAxis = xAxisCopy
                option.yAxis = yAxisCopy

                option.series.forEach((seriesItem) => {
                    if (seriesItem.xAxisIndex !== undefined) {
                        seriesItem.yAxisIndex = seriesItem.xAxisIndex
                        delete seriesItem.xAxisIndex
                    }
                })
            }
            option.series.forEach((series, seriesIndex) => {
                if (!overrides.pictorialBar.data[seriesIndex]) {
                    overrides.pictorialBar.data[seriesIndex] = []
                }

                series.barGap = overrides.pictorialBar.barGap
                series.barCategoryGap = overrides.pictorialBar.barCategoryGap

                series.data.forEach((d, i) => {
                    if (typeof d === 'number') {
                        series.data[i] = Object.assign(
                            { value: d },
                            overrides.pictorialBar.data[seriesIndex][i]
                        )
                    } else {
                        series.data[i] = Object.assign(
                            {},
                            d,
                            overrides.pictorialBar.data[seriesIndex][i]
                        )
                    }
                })
            })
        }

        if (
            (config.seriesType === 'donut' || config.seriesType === 'pie') &&
            config.categoryAxis === 'Time'
        ) {
            option.series.forEach((series, i) => {
                series.data.forEach((d, j) => {
                    option.series[i].data[j] = {
                        name: d.value[0],
                        value: d.value[1],
                    }
                })
            })
        }

        if (config.seriesType === 'gauge') {
            overrides.gauge = _.merge(
                JSON.parse(getDefaultChartPreferences()).gauge,
                overrides.gauge
            )
            overrides.tooltip.show = false
            overrides.legend.show = false
            option.series.forEach((s, i) => {
                s.type = 'gauge'
                if (
                    overrides.gauge?.data &&
                    overrides.gauge.data[0]?.value &&
                    isNumber(overrides.gauge.data[0].value)
                ) {
                    option.series[i].data = overrides.gauge?.data
                } else {
                    const name = chartProperties?.categoryAxisValues?.[0] || ''
                    let value = (
                        ((chartProperties?.series[0]?.data[0]?.value ||
                            chartProperties?.series[0]?.data[0]) *
                            100) /
                        chartProperties?.series[0]?.data.reduce((a, b) => a + (b?.value || b), 0)
                    ).toFixed(3)
                    option.series[i].data = [{ value, name }]
                }
                option.series[i] = {
                    ...s,
                    ...overrides.gauge,
                    data: option.series[i].data,
                }
            })
        }

        applyColumnResize(option, overrides)

        //Remove radius for bar or column charts if they are stacked
        if (config.stacked && (config.seriesType === 'bar' || config.seriesType === 'column')) {
            const stacks = {}

            option.series.forEach((series, i) => {
                const stackName = series.stack || `stack_${i}`
                if (!stacks[stackName]) stacks[stackName] = []

                const hasValidData = Array.isArray(series.data)
                    ? series.data.some((d) => d !== 0 && d !== null)
                    : series.data !== 0 && series.data !== null

                if (hasValidData) stacks[stackName].push(series)
            })

            Object.values(stacks).forEach((stackSeries) => {
                const radiusSeries = []
                for (let i = 0; i < stackSeries[0]?.data.length; i++) {
                    let lastValidIndex = -1
                    stackSeries.forEach((series, j) => {
                        const value = Array.isArray(series.data[i])
                            ? series.data[i][1]
                            : series.data[i]
                        if (value !== 0 && value !== null) {
                            lastValidIndex = j
                        }
                    })
                    if (lastValidIndex !== -1) {
                        radiusSeries.push({
                            data: i,
                            series: lastValidIndex,
                        })
                    }
                }

                stackSeries.forEach((series, index) => {
                    series.data = series.data.map((d, i) => {
                        const isTop = radiusSeries.some((r) => r.data === i && r.series === index)
                        const value = typeof d === 'object' ? d?.value || d : d
                        const itemStyle = isTop
                            ? {
                                  borderRadius: overrides.bar?.itemStyle?.borderRadius || [
                                      5, 5, 0, 0,
                                  ],
                              }
                            : { borderRadius: [0, 0, 0, 0] }

                        return {
                            value,
                            itemStyle,
                        }
                    })
                })
            })
        }
        // apply max legends
        const max = overrides.legend?.maxLegends || 10
        // TO-DO , Nesti/Pepe  Gabe put this try-catch to prevent page crash when data is undefined
        try {
            if (max !== 'all') option.legend.data = option?.legend?.data?.slice(0, max)
        } catch (e) {
            //console.log("data undefined, e=", e)
        }
        // omit props we dont wanna merge
        ;[
            'waterfall',
            'bar',
            'line',
            'pie',
            'donut',
            'pictorialBar',
            'gauge',
            'radialBar',
            'table',
            'xAxis',
            'yAxis',
        ].forEach((key) => {
            overrides = _.omit(overrides, [key])
        })
        option = _.merge(option, overrides)
        applyLegendIconSizeTransformation(option)
        if (dataContainsNegatives(option)) {
            const axis = config.seriesType === 'bar' ? 'xAxis' : 'yAxis'
            option[axis].forEach((axis) => {
                axis.min = function (value) {
                    const absMax = Math.min(Math.abs(value.min), Math.abs(value.max))
                    return -Math.ceil(absMax)
                }
                axis.max = function (value) {
                    const absMax = Math.max(Math.abs(value.min), Math.abs(value.max))
                    return Math.ceil(absMax)
                }
            })
            option.series.forEach((s) => {
                const defaultRadius = s.itemStyle?.borderRadius || [5, 5, 0, 0]
                s.data = s.data.map((d) => {
                    let value
                    if (Array.isArray(d)) {
                        value = d[1]
                    } else if (typeof d === 'object' && d !== null) {
                        value = d.value
                    } else {
                        value = d
                    }
                    const adjustedRadius = [...defaultRadius]
                    if (value < 0) {
                        const tempRadius = [...defaultRadius]
                        if (config.seriesType === 'bar') {
                            adjustedRadius[0] = tempRadius[1]
                            adjustedRadius[3] = tempRadius[2]
                            adjustedRadius[1] = tempRadius[0]
                            adjustedRadius[2] = tempRadius[3]
                        } else {
                            adjustedRadius[0] = tempRadius[2]
                            adjustedRadius[1] = tempRadius[3]
                            adjustedRadius[2] = tempRadius[0]
                            adjustedRadius[3] = tempRadius[1]
                        }
                    }
                    return {
                        value: d,
                        itemStyle: {
                            borderRadius: adjustedRadius,
                        },
                    }
                })
            })
        }
        // fix sorting for bar charts
        if (
            config.sortColumns.some((sortingOption) =>
                ['asc', 'desc'].includes(sortingOption.order)
            ) &&
            config.seriesType === 'bar'
        ) {
            option.series.forEach((series) => {
                series.data = series.data.toReversed()
            })
            option.yAxis.forEach((axis) => {
                axis.data = axis.data.toReversed()
            })
        }
        return option
    }

    const calculateChartContainerProps = () => {
        let width = window.innerWidth
        let left = 0
        let right = 0
        if (configTabOpen) {
            width -= 300
            right += 300
        }
        if (dataTabOpen) {
            width -= 300
            left += 300
        }
        return { width, left, right }
    }

    const renderInsight = () => {
        switch (config.view) {
            case 'table':
                return (
                    <ServerSideGrid
                        handleMenuClick={handleMenuClick}
                        config={config}
                        setConfig={setConfig}
                        embeddedSizeProps={embeddedSizeProps}
                        server={server}
                        clickable={clickable}
                        prompts={activePrompts}
                        handleCreateFilter={handleCreateFilter}
                        setColumnLoading={setColumnLoading}
                        dateFlag={dateFlag}
                        theme={theme}
                    />
                )
            case 'chart':
                return (
                    <ReactECharts
                        option={getOptionWithOverrides()}
                        notMerge={true}
                        lazyUpdate={true}
                        style={chartSetting}
                        theme={ScoopTheme}
                        onEvents={onEvents}
                    />
                )
            case 'kpi':
                return (
                    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                        <KPI
                            config={config}
                            setConfig={setConfig}
                            server={server}
                            embeddedSizeProps={embeddedSizeProps}
                            theme={theme}
                        />
                    </Box>
                )
        }
    }

    const renderNoData = () => {
        return (
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'grid',
                    placeContent: 'center',
                    color: theme?.colorScheme?.darkTheme ? 'white' : 'black',
                }}
            >
                <Typography className={'inter'}>No data found for current configuration</Typography>
            </Box>
        )
    }

    return (
        <>
            {embeddedSizeProps && drillingHistory.length > 0 && (
                <Box className={'drilling-breadcrumbs-container'}>
                    {drillingHistory.map((step, i) => (
                        <Box
                            key={i + step.attribute}
                            className={'drill-step'}
                            sx={{
                                color: theme?.colorScheme?.darkTheme ? 'white' : 'black',
                            }}
                        >
                            <Typography
                                className={`inter ${isStepClickable(i) ? 'clickable-step' : ''}`}
                                mr={'5px'}
                                fontSize={'12px'}
                                onClick={() =>
                                    isStepClickable(i) ? navigateToStep(step, i) : null
                                }
                            >
                                {i < drillingHistory.length - 1
                                    ? step.attribute +
                                      ' = ' +
                                      drillingHistory[i + 1].filter.filterValue.values[0]
                                    : step.attribute}
                            </Typography>
                            {i + 1 === drillingHistory.length && (
                                <IconButton
                                    sx={{ padding: '4px' }}
                                    onClick={() => handleDeleteDrillingStep(step, i)}
                                >
                                    <img
                                        src={
                                            theme?.colorScheme?.darkTheme
                                                ? CloseIconWhite
                                                : CloseIcon
                                        }
                                        height={12}
                                        alt={'delete'}
                                    />
                                </IconButton>
                            )}
                            {i < drillingHistory.length - 1 && (
                                <img
                                    src={CaretRight}
                                    alt={'caret-right'}
                                    style={{ marginRight: '5px' }}
                                />
                            )}
                        </Box>
                    ))}
                </Box>
            )}
            {chartProperties.config.loading || theme === '' ? (
                <Box
                    sx={{
                        height: '100%',
                        display: 'grid',
                        placeContent: 'center',
                    }}
                >
                    <ScoopLoader size={56} />
                </Box>
            ) : validChart() ? (
                <Box
                    sx={
                        !embeddedSizeProps
                            ? {
                                  position: 'absolute',
                                  width: calculateChartContainerProps().width,
                                  left: calculateChartContainerProps().left,
                                  right: calculateChartContainerProps().right,
                                  height: '100%',
                              }
                            : { height: '100%', width: '100%' }
                    }
                >
                    {renderInsight()}
                    {!embeddedSizeProps && (
                        <>
                            <IconButton
                                onClick={() => setDataTabOpen(!dataTabOpen)}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 100,
                                    padding: '4px',
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #E6E4E6',
                                    borderRadius: '0 0 5px 0',
                                }}
                            >
                                <img
                                    src={OpenDrawer}
                                    alt={'OpenDrawerLeft'}
                                    style={{ transform: dataTabOpen ? 'rotate(180deg)' : '' }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => setConfigTabOpen(!configTabOpen)}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    zIndex: 100,
                                    padding: '4px',
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #E6E4E6',
                                    borderRadius: '0 0 0 5px',
                                }}
                            >
                                <img
                                    src={OpenDrawer}
                                    alt={'OpenDrawerRight'}
                                    style={{ transform: !configTabOpen ? 'rotate(180deg)' : '' }}
                                />
                            </IconButton>
                        </>
                    )}
                </Box>
            ) : (
                renderNoData()
            )}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                container={document.getElementById('slide-container')}
                open={openMenu}
                onClose={handleChartMenuClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                style={style}
            >
                {config.view === 'table' ? getTableDrillItems() : getChartDrillItems()}
            </Menu>
        </>
    )
}
