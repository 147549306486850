import { areEquals, packFilter } from '../Filter'

export const NO_FILTER = 'no_filter'

export const DEFAULT_METRIC = {
    workspaceID: '',
    metricName: '',
    metricType: 'KPI',
    derived: false,
    format: {
        formatString: '#,###',
    },
    dateKeyIndex: 0,
    period: 'Monthly',
    calendarType: 'Rolling',
    measureName: '',
    reportSeriesTableID: '',
}

export const METRIC_PROPS = {
    FORMAT: 'format',
    FORMULA: 'formula',
    SOURCE: 'source',
    AGGREGATION: 'aggregation',
    FILTERS: 'filters',
    PERIOD: 'period',
}

export const METRIC_TABS = [
    { key: METRIC_PROPS.SOURCE, label: 'Source' },
    { key: METRIC_PROPS.FORMAT, label: 'Format' },
    { key: METRIC_PROPS.AGGREGATION, label: 'Aggregation' },
    { key: METRIC_PROPS.FILTERS, label: 'Filters' },
    { key: METRIC_PROPS.PERIOD, label: 'Period' },
]

export const DERIVED_METRIC_TABS = [
    { key: METRIC_PROPS.FORMULA, label: 'Formula' },
    { key: METRIC_PROPS.FORMAT, label: 'Format' },
]

export const getPeriodPlural = (period) => {
    switch (period) {
        case 'Daily':
            return 'days'
        case 'Weekly':
            return 'weeks'
        case 'Monthly':
            return 'months'
        case 'Quarterly':
            return 'quarters'
        case 'Yearly':
            return 'years'
        default:
            return null
    }
}

export const getInbox = (inboxID, workspaceMetadata) => {
    return workspaceMetadata.inboxes.find((i) => i.inboxID === inboxID)
}

export const getTable = (reportSeriesTableID, inboxID, workspaceMetadata) => {
    return getInbox(inboxID, workspaceMetadata).tables.find(
        (t) => t.reportSeriesTableID === reportSeriesTableID
    )
}

export const getColumn = (columnName, reportSeriesTableID, inboxID, workspaceMetadata) => {
    return getTable(reportSeriesTableID, inboxID, workspaceMetadata).columns.find(
        (c) => c.columnName === columnName
    )
}

export const removeFilter = (filterItem, metricFilter) => {
    if (metricFilter.boperator) {
        const newFilters = []
        metricFilter.filters.forEach((f) => {
            if (!areEquals(f, filterItem)) newFilters.push(f)
        })
        return packFilter(newFilters)
    } else {
        if (areEquals(metricFilter, filterItem)) {
            return null
        }
    }
    return metricFilter
}
