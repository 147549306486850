import Button from '../Button/Button'
import Input from '../Input/Input'
import Dialog from './Dialog'
import React, { useState } from 'react'
import { logEvent } from '../../../api/hooks/logs/api'
import { useUserInfo } from '../../../api/hooks/user/useUserInfo'
import { useWorkspaces } from '../../../api/hooks/workspaces/useWorkspaces'

export const IntegrationRequestDialog = ({ open, goBack, title, setAlert }) => {
    const [integrationRequest, setIntegrationRequest] = useState('')

    const { user } = useUserInfo()
    const { submit, isSubmittingRequest } = useWorkspaces()

    const handleSubmitRequest = () => {
        submit({
            email: user?.userName,
            message: integrationRequest,
            onSuccess: () => {
                goBack()
                setIntegrationRequest('')
                setAlert({
                    severity: 'success',
                    message: 'Your request has been submitted successfully!',
                })
            },
        })
        logEvent('newConnectorFlow', {
            type: 'newUnavailableConnectorFlow',
            api: {
                connector: 'Request',
                action: integrationRequest,
            },
        })
    }

    return (
        <Dialog
            actions={
                <>
                    <Button className={'button-grey small'} onClick={goBack}>
                        Back
                    </Button>
                    <Button
                        disabled={!integrationRequest}
                        className={'button-purple small'}
                        onClick={handleSubmitRequest}
                        loading={isSubmittingRequest}
                    >
                        Submit
                    </Button>
                </>
            }
            style={{ minWidth: 480 }}
            open={open}
            onClose={() => {
                goBack()
                setIntegrationRequest('')
            }}
            title={title}
        >
            <Input
                placeholder={'Type in details of your request'}
                onChange={(e) => setIntegrationRequest(e.target.value)}
                value={integrationRequest}
                size={'small'}
                multiline={true}
                rows={5}
                padding={'4px'}
            />
        </Dialog>
    )
}
