import React from 'react'
import './AuthLayout.css'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../../router/routes'
import { Box } from '@mui/material'
import NavBar from '../../NavBar/NavBar'

export const AuthLayout = () => {
    const location = useLocation()
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const workspaceName = useSelector((state) => state.auth.workspaceName)

    const queryParams = new URLSearchParams(window.location.search)
    const inviteCode = queryParams.get('invite')

    // Redirect to the logout route if the forceLogin query parameter is present
    const forceLogin = queryParams.get('forceLogin')
    if (forceLogin) {
        localStorage.setItem('forceLogin', forceLogin)
        return <Navigate to={ROUTES.LOGOUT} />
    }

    // Redirect to the logout route if the filledLogin query parameter is present (when user fills out form on our site or airtable etc)
    const filledLogin = queryParams.get('filledLogin')
    if (filledLogin) {
        localStorage.setItem('filledLogin', filledLogin)
        return <Navigate to={ROUTES.LOGOUT} />
    }

    if (location.pathname === '/' && (isAuthenticated || inviteCode)) {
        return <Navigate to={ROUTES.CANVAS_DASHBOARD} />
    }

    return isAuthenticated || inviteCode ? (
        <Box className={'app-container'}>
            <NavBar workspaceName={workspaceName} />
            <Outlet />
        </Box>
    ) : (
        <Navigate to={ROUTES.LOGIN} />
    )
}
