import Dialog from '../common/Dialog/Dialog'
import { Box, IconButton, Skeleton } from '@mui/material'
import Button from '../common/Button/Button'
import * as React from 'react'
import Selector from '../common/Selector/Selector'
import Typography from '@mui/material/Typography'
import SearchBar from '../common/SeacrhBar/SearchBar'
import { useWorkspaceMetadata } from '../../api/hooks/workspaceMetadata/useWorkspaceMetadata'
import _, { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { useCategoryValues } from '../../api/hooks/categoryValues/useCategoryValues'
import Checkbox from '../common/Checkbox/Checkbox'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import TrashRed from '../../assets/icons/TrashRed.svg'
import Input from '../common/Input/Input'
import { packFilter, unpackFilters } from './Filter'

export const AddFilterModal = ({
    config,
    setConfig,
    chartProperties,
    analyzeChanges,
    open,
    close,
    saveFilter,
    editState,
}) => {
    const [filterBy, setFilterBy] = useState(null)
    const [operator, setOperator] = useState('Equals')
    const [searchValue, setSearchValue] = useState('')
    const [selectedItems, setSelectedItems] = useState([])
    const [likeValues, setLikeValues] = useState([])
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('')
    const [renderedCategoryValues, setRenderedCategoryValues] = useState([])
    const [savedFilterChecked, setSavedFilterChecked] = useState(false)
    const [savedFilterName, setSavedFilterName] = useState('')

    const scrollableTarget = useRef()

    const { workspaceMetadata } = useWorkspaceMetadata()
    const { data: categoryValues, isLoading: categoryValuesLoading } = useCategoryValues(
        filterBy?.columnName,
        debouncedSearchValue,
        filterBy?.reportSeriesTableID
    )

    const debounceSearch = useCallback(
        debounce((v) => setDebouncedSearchValue(v), 300),
        []
    )

    const isNumeric = filterBy && ['Decimal', 'Currency', 'Integer'].includes(filterBy.columnType)
    const isDate = filterBy && filterBy.columnType === 'DateTime'
    const isLike = ['Like', 'NotLike'].includes(operator)
    const showCategoryOptions = ['Equals', 'NotEquals'].includes(operator)
    const isEditing = !!editState

    useEffect(() => {
        if (editState) {
            setOperator(editState.operator)
            setSelectedItems(editState.filterValue?.values)
            if (editState.operator === 'Like' || editState.operator === 'NotLike') {
                setLikeValues(editState.filterValue.values)
            }
            handleFilterByChange(editState.attributeName)
        }
    }, [])

    useEffect(() => {
        if (categoryValues.length > 0) setRenderedCategoryValues(categoryValues.slice(0, 50))
    }, [categoryValues])

    const getAvailableDateFilters = () => {
        const tableIds = config.selectedItems.map((i) => i.reportSeriesTableID)
        const dates = []
        workspaceMetadata?.inboxes?.forEach((inbox) => {
            inbox.tables.forEach((table) => {
                if (tableIds.includes(table.reportSeriesTableID)) dates.push(table.dates || [])
            })
        })
        return _.intersection(...dates)
    }

    const getFilterByOptions = () => {
        const filterOptions = []

        if (config.view === 'table') {
            if (config.worksheetID && config.rangeName) {
                const tableDrillCols =
                    chartProperties.drillAttributes?.map((item) => ({
                        value: item,
                        label: item,
                    })) || []
                filterOptions.push(...tableDrillCols)
            } else {
                const tables = []
                workspaceMetadata?.inboxes?.forEach((inbox) => {
                    inbox.tables.forEach((table) => {
                        if (config.selectedTables.includes(table.reportSeriesTableID)) {
                            tables.push(table)
                        }
                    })
                })
                const columns = tables.map((table) =>
                    table.columns.map((col) => ({
                        ...col,
                        reportSeriesTableID: table.reportSeriesTableID,
                    }))
                )
                const intersectedColumns = _.intersection(...columns).map((col) => ({
                    value: col.columnName,
                    label: col.columnName,
                }))
                filterOptions.push(...intersectedColumns)
            }
        } else {
            const dateCols =
                getAvailableDateFilters().map((date) => ({
                    value: date,
                    label: date,
                })) || []
            const drillCols =
                chartProperties.drillAttributes?.map((item) => ({
                    value: item,
                    label: item,
                })) || []
            const changeDrillCols = analyzeChanges
                ? chartProperties.changeDrillAttributes?.map((item) => ({
                      value: item,
                      label: item,
                  }))
                : []
            filterOptions.push(...dateCols, ...drillCols, ...changeDrillCols)
        }
        return filterOptions
    }

    const getFilterOperators = () => {
        const options = [
            { value: 'Equals', label: 'Equals' },
            { value: 'NotEquals', label: 'Not Equals' },
        ]

        if (isNumeric || isDate) {
            options.push(
                { value: 'GreaterThan', label: 'Greater Than' },
                { value: 'GreaterThanOrEquals', label: 'Greater Than or Equals' },
                { value: 'LessThan', label: 'Less Than' },
                { value: 'LessThanOrEquals', label: 'Less Than or Equals' }
            )
        }

        if (!isNumeric && !isDate) {
            options.push({ value: 'Like', label: 'Like' }, { value: 'NotLike', label: 'Not Like' })
        }

        if (isDate) options.push({ value: 'Between', label: 'Between' })

        return options
    }

    const handleFilterByChange = (value) => {
        const tableIds = config.selectedItems.map((i) => i.reportSeriesTableID)
        workspaceMetadata?.inboxes?.forEach((inbox) => {
            inbox.tables.forEach((table) => {
                if (tableIds.includes(table.reportSeriesTableID)) {
                    const column = table.columns.find((col) => col.columnName === value)
                    setFilterBy({
                        ...column,
                        reportSeriesTableID: table.reportSeriesTableID,
                    })
                }
            })
        })
    }

    const handleNextScroll = () => {
        const currentLength = renderedCategoryValues.length
        setRenderedCategoryValues(categoryValues.slice(0, currentLength + 50))
    }

    const handleSearchChange = (e) => {
        debounceSearch(e.target.value)
        setSearchValue(e.target.value)
    }

    const handleCheckSelectedItems = (value) => {
        if (selectedItems.includes(value)) {
            setSelectedItems(selectedItems.filter((i) => i !== value))
        } else {
            setSelectedItems([...selectedItems, value])
        }
    }

    const addLikeValue = () => {
        if (!searchValue) return
        setLikeValues([...likeValues, searchValue])
        setSelectedItems([...selectedItems, searchValue])
        setSearchValue('')
    }

    const removeLikeValue = (value) => {
        setLikeValues(likeValues.filter((v) => v !== value))
        setSelectedItems(selectedItems.filter((i) => i !== value))
    }

    const handleAddFilter = async (e) => {
        e.stopPropagation()

        let attributeFilter = null
        let index = 0
        let filterList = unpackFilters(chartProperties.config.filter)

        for (let f of filterList) {
            if (f.attributeName === filterBy.columnName && f.operator === operator) {
                attributeFilter = f
                break
            }
            index++
        }
        if (selectedItems.length === 0) {
            if (attributeFilter !== null) {
                filterList.splice(index, 1)
            }
        } else {
            if (attributeFilter == null) {
                if (operator === 'Between') {
                    let newFilter = {
                        attributeName: filterBy.columnName,
                        operator: 'GreaterThanOrEquals',
                        filterValue: {
                            values: [selectedItems[0]],
                        },
                    }
                    filterList.push(newFilter)
                    newFilter = {
                        attributeName: filterBy.columnName,
                        operator: 'LessThanOrEquals',
                        filterValue: {
                            values: [selectedItems[1]],
                        },
                    }
                    filterList.push(newFilter)
                } else {
                    attributeFilter = {
                        attributeName: filterBy.columnName,
                        operator: operator,
                        filterValue: {
                            values: selectedItems,
                        },
                    }
                    filterList.push(attributeFilter)
                }
            } else {
                attributeFilter.operator = operator
                attributeFilter.filterValue = {
                    values: selectedItems,
                }
            }
        }
        let newFilter = packFilter(filterList)
        config.filter = newFilter
        chartProperties.config.filter = newFilter
        setConfig({ ...config })
        chartProperties.getResults(config)
        if (savedFilterChecked) await saveFilter(savedFilterName, newFilter)
        close()
    }

    return (
        <Dialog
            open={open}
            onClose={close}
            title={isEditing ? 'Edit filter' : 'Add new filter'}
            actions={
                <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
                    <Button className={'button-grey small'} onClick={close} text={'Cancel'} />
                    <Button
                        disabled={!filterBy || !operator}
                        onClick={handleAddFilter}
                        className={'button-purple small'}
                    >
                        {isEditing ? 'Save Changes' : 'Add filter'}
                    </Button>
                </Box>
            }
            style={{ width: 600 }}
        >
            <Box display="flex" gap="8px" width="100%">
                <Selector
                    key={filterBy?.columnName}
                    value={filterBy?.columnName}
                    onChange={(e) => handleFilterByChange(e.target.value)}
                    label={'Filter by'}
                    sx={{ height: 36 }}
                    labelClassName={'selector-label-bold'}
                >
                    {getFilterByOptions().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Selector>
                <Selector
                    value={operator}
                    onChange={(e) => setOperator(e.target.value)}
                    label={'Operator'}
                    sx={{ height: 36 }}
                    labelClassName={'selector-label-bold'}
                >
                    {getFilterOperators().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Selector>
            </Box>
            <Box display="flex" flexDirection="column" gap="8px">
                <Typography className={'inter selector-label-bold'}>Value</Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                    sx={{
                        borderRadius: '5px',
                        padding: '16px',
                        backgroundColor: '#F9F9F9',
                        minHeight: '360px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <SearchBar
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={isLike ? 'Add value to match' : 'Search for a value'}
                            sx={{ width: '100%' }}
                            noIcon={isLike}
                        />
                        {isLike && (
                            <Button
                                className={'button-purple small'}
                                sx={{ ml: '10px' }}
                                onClick={addLikeValue}
                                disabled={!searchValue}
                            >
                                Add value
                            </Button>
                        )}
                    </Box>
                    {showCategoryOptions && (
                        <Box
                            ref={scrollableTarget}
                            display="flex"
                            flexDirection="column"
                            gap="6px"
                            sx={{ maxHeight: '269px', overflow: 'auto' }}
                        >
                            {categoryValuesLoading ? (
                                [...Array(10)].map((_, i) => <Skeleton key={i} height={36} />)
                            ) : (
                                <InfiniteScroll
                                    scrollableTarget={scrollableTarget.current}
                                    dataLength={renderedCategoryValues.length}
                                    next={handleNextScroll}
                                    hasMore={renderedCategoryValues.length < categoryValues.length}
                                    loader={<ScoopLoader />}
                                >
                                    {renderedCategoryValues.map((value) => (
                                        <Box
                                            key={value}
                                            display="flex"
                                            gap="8px"
                                            alignItems="center"
                                        >
                                            <Checkbox
                                                checked={selectedItems.includes(value)}
                                                onClick={() => handleCheckSelectedItems(value)}
                                                size={'medium'}
                                            />
                                            <Typography sx={{ color: '#14092A', fontSize: '14px' }}>
                                                {value}
                                            </Typography>
                                        </Box>
                                    ))}
                                </InfiniteScroll>
                            )}
                        </Box>
                    )}
                    {isLike && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap="6px"
                            sx={{ maxHeight: '269px', overflow: 'auto' }}
                        >
                            {likeValues.map((value) => (
                                <Box
                                    key={value}
                                    display="flex"
                                    gap="8px"
                                    alignItems="center"
                                    justifyContent={'space-between'}
                                >
                                    <Typography sx={{ color: '#14092A', fontSize: '14px' }}>
                                        {value}
                                    </Typography>
                                    <IconButton onClick={() => removeLikeValue(value)}>
                                        <img
                                            src={TrashRed}
                                            alt={'Trash Icon'}
                                            style={{ height: 20 }}
                                        />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
                <Box>
                    {!isEditing && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Checkbox
                                checked={savedFilterChecked}
                                onClick={() => setSavedFilterChecked(!savedFilterChecked)}
                                size={'medium'}
                            />
                            <Typography
                                className={'inter'}
                                sx={{ fontSize: '14px', marginLeft: '5px' }}
                            >
                                Save as saved filter and replace current selection
                            </Typography>
                        </Box>
                    )}
                    {savedFilterChecked && (
                        <Box sx={{ mt: 1 }}>
                            <Input
                                placeholder={'Enter saved filter name'}
                                style={{ width: '60%' }}
                                label={'Saved filter name'}
                                value={savedFilterName}
                                onChange={(e) => setSavedFilterName(e.target.value)}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </Dialog>
    )
}
