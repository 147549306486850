import Dialog from '../../../common/Dialog/Dialog'
import Input from '../../../common/Input/Input'
import { Box, Popover } from '@mui/material'
import Button from '../../../common/Button/Button'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from '../../../../api/api'
import { useNavigate } from 'react-router-dom'
import UploadPPT from '../../../Objects/UploadPPT'
import PlusRed from '../../../../assets/icons/PlusRed.svg'
import ShapesRed from '../../../../assets/icons/ShapesRed.svg'
import MicrosoftPowerPoint from '../../../../assets/icons/MicrosoftPowerpointLogo.svg'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import { DialogItem } from '../../../common/DialogItem/DialogItem'
import { NewFromRecipe } from '../../Source/NewDatasetModal/NewFromRecipe'
import { GenerateInsightDialog } from '../../../Insights/GenerateInsightDialog'
import MagicWand from '../../../../assets/icons/MagicWandRed.svg'
import { setOnboardingChecklist, setPresentationLoading } from '../../../../store/actions/uiActions'
import { useCanvases } from '../../../../api/hooks/canvases/useCanvases'
import { logEvent } from '../../../../api/hooks/logs/api'
import { useWorkspaces } from '../../../../api/hooks/workspaces/useWorkspaces'
import { useUserInfo } from '../../../../api/hooks/user/useUserInfo'
import { useWorkspaceMetadata } from '../../../../api/hooks/workspaceMetadata/useWorkspaceMetadata'
import { getWorkspaceID } from '../../../../utils'
import userflow from 'userflow.js'

export const NewCanvasDialog = ({ anchorEl, onClose, startInterval, open }) => {
    const navigate = useNavigate()
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const openByOnboarding = useSelector((state) => state.ui.showRecipeModal)
    const onboardingChecklist = useSelector((state) => state.ui.onboardingChecklist)

    const [canvasName, setCanvasName] = useState(null)
    const dispatch = useDispatch()
    const [showPPTUpload, setShowPPTUpload] = useState(false)
    const [showNewFromRecipe, setShowNewFromRecipe] = useState(false)
    const [showCreateCanvas, setShowCreateCanvas] = useState(false)
    const [generateInsightDialogOpen, setGenerateInsightDialogOpen] = useState(false)
    const { createCanvas, isCreating } = useCanvases()

    const { workspaces: workspacesAccessible } = useWorkspaces()
    const { user } = useUserInfo()
    const { workspaceMetadata, isLoading: workspaceMetadataLoading } = useWorkspaceMetadata()
    const workspaceID = getWorkspaceID()

    useEffect(() => {
        if (!Boolean(anchorEl)) setCanvasName(null)
    }, [Boolean(anchorEl)])

    useEffect(() => {
        const recipeMapping = localStorage.getItem('recipeMapping')
        if (recipeMapping) setShowNewFromRecipe(true)
    }, [])

    const createCanvasFromPPT = () => {
        setShowPPTUpload(true)
        logEvent('newCanvasFromPresentation', { type: 'attemptNewCanvasFromPresentation' })
        onClose()
    }

    const closePPTUpload = () => {
        setShowPPTUpload(false)
    }

    const handleOnCreateSuccess = (newCanvasID) => {
        navigate(newCanvasID, { state: 'newCanvas' })
        onClose()
        try {
            userflow.track('created_new_canvas', {
                canvasID: `${newCanvasID}`,
                canvasName: canvasName,
            })
            // console.log('sent userflow event', 'created_new_canvas', {
            //     canvasID: `${newCanvasID}`,
            //     CanvasName: canvasName,
            // })
        } catch (error) {}
        // GJ Jan 23: Commented out because UserFlow is supposed to take over the onboarding checklist
        // if (onboardingChecklist === '2') dispatch(setOnboardingChecklist('3'))
    }

    const isDefaultWorkspace = user?.defaultTour
        ? user.defaultTour === workspaceID
        : workspacesAccessible.find((w) => w.name === 'Tour Scoop')?.workspaceID === workspaceID

    const actions = (
        <Box display="flex" justifyContent="space-between" width="100%">
            <Button
                onClick={() => {
                    setShowCreateCanvas(false)
                    onClose()
                }}
                className={'primary-button button-grey small'}
                text={'Cancel'}
            ></Button>
            <Button
                onClick={() => createCanvas({ canvasName, onSuccess: handleOnCreateSuccess })}
                className={'primary-button button-purple small'}
                disabled={!canvasName || isCreating}
            >
                {isCreating ? <ScoopLoader size={26} /> : 'Create blank canvas'}
            </Button>
        </Box>
    )

    const renderCreateCanvasDialog = () => {
        return (
            <Dialog
                open={showCreateCanvas}
                onClose={() => setShowCreateCanvas(false)}
                title={'Create New Canvas'}
                actions={actions}
                fullWidth
            >
                <Input
                    padding={'8px'}
                    placeholder={'Enter Canvas Name'}
                    value={canvasName}
                    onChange={(event) => setCanvasName(event.target.value)}
                />
            </Dialog>
        )
    }

    const renderNewPopover = () => {
        return (
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    marginTop: '8px',
                    boxShadow: '0px 0px 7px 0px rgba(20, 9, 42, 0.25)',
                }}
            >
                <Box sx={{ padding: '4px 0', borderRadius: '5px' }}>
                    <DialogItem
                        description={'Create an empty canvas to design yourself'}
                        title={'Blank Canvas'}
                        icon={PlusRed}
                        onClick={() => {
                            setShowCreateCanvas(true)
                            onClose()
                        }}
                    />
                    <DialogItem
                        disabledStyles={isDefaultWorkspace}
                        description={'Bring your Powerpoint slides into a new canvas'}
                        title={'Canvas from Presentation'}
                        onClick={createCanvasFromPPT}
                        icon={MicrosoftPowerPoint}
                    />
                    <DialogItem
                        disabledStyles={isDefaultWorkspace}
                        description={'Pre-made canvases for telling specific data stories'}
                        title={'Canvas from Recipe'}
                        icon={ShapesRed}
                        onClick={() => {
                            setShowNewFromRecipe(true)
                            onClose()
                        }}
                    />
                    <DialogItem
                        description={'Instantly generate a presentation with the help of AI'}
                        title={'Canvas from AI'}
                        icon={MagicWand}
                        onClick={handleOpenGenerateInsightDialog}
                    />
                </Box>
            </Popover>
        )
    }

    const handleOpenGenerateInsightDialog = async () => {
        setGenerateInsightDialogOpen(true)
        onClose()
    }

    const handleOnGenerateSuccess = () => {
        dispatch(setPresentationLoading(true))
        if (onboardingChecklist === '2') dispatch(setOnboardingChecklist('3'))
        startInterval()
        onClose()
    }

    return (
        <>
            {renderNewPopover()}
            {renderCreateCanvasDialog()}
            {showPPTUpload && (
                <UploadPPT isOpen={showPPTUpload} onClose={closePPTUpload} title={canvasName} />
            )}
            {(showNewFromRecipe || openByOnboarding) && (
                <NewFromRecipe
                    isOpen={showNewFromRecipe || openByOnboarding}
                    setOpen={setShowNewFromRecipe}
                />
            )}
            {generateInsightDialogOpen && (
                <GenerateInsightDialog
                    open={generateInsightDialogOpen}
                    onClose={() => setGenerateInsightDialogOpen(false)}
                    inboxes={workspaceMetadata?.inboxes || []}
                    onGenerateSuccess={handleOnGenerateSuccess}
                    onGenerateError={() => {}}
                    generateInsight={false}
                    workspaceMetadata={workspaceMetadata}
                />
            )}
        </>
    )
}
