// authReducer.js
import { validateToken } from '../../api/tokenValidation'
import { UPDATE_CANVASES } from '../actions'
import { SET_GUEST_PROMPTS, UPDATE_WORKSPACE_NAME, UPDATE_WORKSPACES } from '../actions/authActions'
import { getUserID, setLastUsedWorkspaces } from '../../utils'

const initialState = {
    userFirstName: null,
    userLastName: null,
    accountID: null,
    googleAccount: null,
    userID: null,
    userEmail: null,
    token: null,
    refreshToken: null,
    isAuthenticated: validateToken(localStorage.getItem('userToken')),
    workspaceID: localStorage.getItem('workspaceID') || '', // Read from local storage or set to null to invoke open a workspace dialog
    workspaceName: localStorage.getItem('workspaceName') || '', // Read from local storage or set to null to invoke open a workspace dialog
    apiURL: process.env.REACT_APP_API_URL,
    workspacesAccessible: [],
    workspaceMetadata: null,
    canvasID: null, //TO-DO: eventually this will need to support all canvases avail to the user, but for now it's just for one specific canvas
    canvasName: null,
    canvases: [],
    isGuestMode: false, //if true, no real user here; just a guest who got in via invite so bypass auth token in calls and restrict access
    guestPrompts: [],
    autoShowTourWorkspace: true,
    defaultTourWorkspace: null,
    personalWorkspace: null,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_AUTHENTICATED':
            localStorage.setItem('userToken', action.user?.signInUserSession.idToken.jwtToken)
            localStorage.setItem('refreshToken', action.user?.signInUserSession.refreshToken.token)
            localStorage.setItem('userID', action.user?.username)
            localStorage.setItem('userName', action.user?.attributes.email)
            localStorage.setItem('userFirstName', action.user?.attributes.userFirstName)
            if (action.user?.attributes.email) {
                localStorage.setItem('userEmail', action.user?.attributes.email)
            }
            return {
                ...state,
                isAuthenticated: action.isAuthenticated,
                userID: action.user?.username,
                userName: action.user?.attributes.email,
                userEmail: action.user?.attributes.email,
                token: action.user?.signInUserSession.idToken.jwtToken,
                refreshToken: action.user?.signInUserSession.refreshToken.token,
            }
        case 'LOGOUT':
            const forceLogin = localStorage.getItem('forceLogin') // Save the value before clearing
            const filledLogin = localStorage.getItem('filledLogin') // Save the value before clearing
            const lastUsedWorkspaces = localStorage.getItem('lastUsedWorkspaces') // Save the value before clearing

            localStorage.clear() // Clear all localStorage

            if (forceLogin) localStorage.setItem('forceLogin', forceLogin) // Restore `forceLogin`
            if (filledLogin) localStorage.setItem('filledLogin', filledLogin) // Restore `filledLogin`
            if (lastUsedWorkspaces) localStorage.setItem('lastUsedWorkspaces', lastUsedWorkspaces) // Restore `forceLogin`

            return {
                ...initialState,
                workspaceID: localStorage.getItem('workspaceID') || '',
                workspaceName: localStorage.getItem('workspaceName') || '',
                isAuthenticated: validateToken(localStorage.getItem('userToken')),
            }
        case 'UPDATE_USER_INFO':
            return {
                ...state,
                accountID: action.user.attributes.accountID,
                userFirstName: action.user.attributes.firstName,
                userLastName: action.user.attributes.lastName,
                googleAccount: action.user.attributes.googleAccount,
                workspacesAccessible: action.user.attributes.workspacesAccessible,
                defaultTourWorkspace: action.user.attributes.defaultTourWorkspace,
                personalWorkspace: action.user.attributes.personalWorkspace,
            }
        case 'UPDATE_CANVAS':
            return {
                ...state,
                canvasID: action.canvasID,
                canvasName: action.canvasName,
            }
        case 'SWITCH_WORKSPACE':
            localStorage.setItem('workspaceID', action.workspaceID)
            localStorage.setItem('workspaceName', action.workspaceName)
            setLastUsedWorkspaces(getUserID(), action.workspaceID, action.workspaceName)
            return {
                ...state,
                workspaceID: action.workspaceID,
                workspaceName: action.workspaceName,
            }
        case 'UPDATE_CANVASES':
            return {
                ...state,
                canvases: action.payload,
            }
        case 'SET_GUEST_MODE':
            return {
                ...state,
                isGuestMode: true,
            }
        case SET_GUEST_PROMPTS:
            return {
                ...state,
                guestPrompts: action.payload.guestPrompts,
            }
        case 'SET_AUTO_SHOW_TOUR_WORKSPACE':
            return {
                ...state,
                autoShowTourWorkspace: false,
            }
        case UPDATE_WORKSPACE_NAME:
            const updatedWorkspaces = action.payload.workspaces.map((workspace) =>
                workspace.workspaceID === action.payload.workspaceID
                    ? { ...workspace, name: action.payload.workspaceName }
                    : workspace
            )
            localStorage.setItem('workspaceName', action.payload.workspaceName)
            return {
                ...state,
                workspaceName:
                    action.payload.workspaceID === state.workspaceID
                        ? action.payload.workspaceName
                        : state.workspaceName,
                workspacesAccessible: [...updatedWorkspaces],
            }
        case UPDATE_WORKSPACES:
            return {
                ...state,
                workspacesAccessible: action.payload,
            }
        default:
            return state
    }
}

export default authReducer
